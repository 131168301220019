import { createSlice } from '@reduxjs/toolkit';

const inititalState = {
  slugType: 0,
};

const librarySlice = createSlice({
  name: 'library',
  initialState: inititalState,
  reducers: {
    updateCategoryFilter: (state, { payload }) => {
      state.slugType = payload;
    },
    resetLibraryState: () => ({ ...inititalState }),
  },
});

export const { updateCategoryFilter, resetLibraryState } = librarySlice.actions;
export const libraryState = (state) => state.library;

const libraryReducer = librarySlice.reducer;
export default libraryReducer;
