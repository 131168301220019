import { LogWithPrefix } from "../../../utils/Log"
import React from "react"

const Log = new LogWithPrefix("ErrorBoundary")

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }
    static getDerivedStateFromError(error) {
        Log.error(error)
        return { hasError: true }
    }
    componentDidCatch(error, errorInfo) {
        Log.error({ error, errorInfo })
    }
    render() {
        if (this.state.hasError && process.env.NODE_ENV !== "production") {
            return (
                <div>
                    <h2>Oops, there is an error!</h2>
                    <button
                        type="button"
                        onClick={() => this.setState({ hasError: false })}
                    >
                        Try again?
                    </button>
                </div>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary