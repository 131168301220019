import { openDB as C } from "idb";
import g from "axios";
const d = async () => await C("assemblr-db", 1, {
  upgrade(t) {
    const e = t.createObjectStore("creations", {
      keyPath: "Id",
      autoIncrement: !0
    });
    e.createIndex("HostID", "HostID"), e.createIndex("Slug", "Slug"), e.createIndex("Pblr", "Pblr"), t.createObjectStore("preferences", {
      keyPath: "key"
    }), t.createObjectStore("apis", {
      keyPath: "key"
    });
  }
}), u = {
  async initDB() {
    await d();
  },
  async getMyCreations({ hostId: t }) {
    return await (await d()).transaction("creations", "readonly").objectStore("creations").index("HostID").getAll(t);
  },
  async updateOrCreateCreations({ creations: t }) {
    const n = (await d()).transaction("creations", "readwrite").objectStore("creations");
    await Promise.all(t.map(async (r) => {
      await n.get(r.Id) ? await n.put(r) : await n.add(r);
    }));
  },
  async getPreference({ key: t }) {
    const r = await (await d()).transaction("preferences", "readonly").objectStore("preferences").get(t);
    return (r == null ? void 0 : r.value) || null;
  },
  async setPreference({ key: t, value: e }) {
    await (await d()).transaction("preferences", "readwrite").objectStore("preferences").put({ key: t, value: e });
  },
  async deleteCreation({ Pblr: t }) {
    const n = (await d()).transaction("creations", "readwrite").objectStore("creations"), i = await n.index("Pblr").get(t);
    return i && await n.delete(i.Id), !0;
  },
  async renameCreation({ Pblr: t, name: e }) {
    const r = (await d()).transaction("creations", "readwrite").objectStore("creations"), s = await r.index("Pblr").get(t);
    return s && (s.Name = e, await r.put(s)), !0;
  },
  async setApi({ key: t, value: e }) {
    await (await d()).transaction("apis", "readwrite").objectStore("apis").put({ key: t, value: e });
  },
  async getApi({ key: t }) {
    const r = await (await d()).transaction("apis", "readonly").objectStore("apis").get(t);
    return (r == null ? void 0 : r.value) || null;
  },
  async deleteApi({ key: t }) {
    return await (await d()).transaction("apis", "readwrite").objectStore("apis").delete(t), !0;
  }
}, y = "https://asblr.app/api", P = "https://flask-cloud-run-946233551537.asia-southeast2.run.app/api", b = g.create({
  baseURL: y
});
b.interceptors.request.use(
  (t) => {
    const e = localStorage.getItem("_token");
    return e && (t.headers.Authorization = e), t;
  },
  (t) => Promise.reject(t)
);
const p = {
  getMyCreations: async ({ page: t = 1, limit: e = 20, type: a = "all", pageSize: n = 20 }) => {
    const r = new AbortController(), i = r.signal, s = {
      published: a === "published" ? "True" : a === "unpublished" ? "False" : a === "draft" ? "Null" : void 0,
      is_template: a === "notduplicatable" ? "False" : a === "duplicatable" ? "True" : void 0,
      type: a
    };
    try {
      const c = setTimeout(() => r.abort(), 1e4), l = await b.get("/v2/creations/my-creations/", {
        params: {
          page: t,
          limit: e,
          row: n,
          ...s
        },
        signal: i
      });
      return clearTimeout(c), l.data;
    } catch {
      return (await b.get("/v2/creations/my-creations/", {
        baseURL: P,
        params: {
          page: t,
          limit: e,
          row: n,
          ...s
        }
      })).data;
    }
  },
  deleteCreation: async ({ Pblr: t }) => {
    const { data: e } = await b.post("/v2/creations/delete-creations/", {
      creations: t
    });
    return e;
  },
  renameCreation: async ({ Pblr: t, name: e }) => {
    const { data: a } = await b.post("/v2/creations/update-creations/", {
      creations: t,
      name: e
    });
    return a;
  }
}, f = typeof window < "u" && "indexedDB" in window, h = {
  init: async () => {
    f && await u.initDB();
  },
  getMyCreations: async ({ page: t = 1, force: e = !1, type: a = "all", hostId: n, localOnly: r = !1 }) => {
    const i = `my-creations-${n}-${a}-${t}`;
    if (!f) {
      const l = await p.getMyCreations({ page: t, type: a });
      return {
        data: l.creations,
        total: l.jumlah,
        totalPage: l.page,
        page: t
      };
    }
    const s = await u.getApi({ key: i });
    if (s && !e) {
      const w = (await u.getMyCreations({ hostId: n })).filter((o) => a === "all" ? !0 : a === "published" ? o.Published : a === "unpublished" ? !o.Published && o.Published !== null : a === "draft" ? o.Published === null : a === "notduplicatable" ? !o.IsTemplate : a === "duplicatable" ? o.IsTemplate : !0).sort((o, x) => new Date(x.UpdatedTime || x.CreatedTime) - new Date(o.UpdatedTime || o.CreatedTime)).slice((t - 1) * 20, t * 20);
      if (w.map((o) => o.Pblr).join() === s.creationIds.join())
        return {
          data: w,
          total: s.total,
          totalPage: s.totalPage,
          page: t
        };
      const m = w.filter((o) => !s.creationIds.includes(o.Pblr));
      if (m.length > 0)
        for (const o of m)
          await u.deleteCreation({ Pblr: o.Pblr });
      if (r)
        return {
          data: [],
          total: 0,
          totalPage: 0,
          page: t
        };
    } else if (r)
      return {
        data: [],
        total: 0,
        totalPage: 0,
        page: t
      };
    const c = await p.getMyCreations({ page: t, type: a }).catch(async (l) => {
      console.log("Error in getMyCreations", l);
      const w = await h.getMyCreations({ page: t, force: !1, type: a, hostId: n, localOnly: !0 });
      return {
        creations: w.data,
        jumlah: w.total,
        page: w.page
      };
    });
    return await u.updateOrCreateCreations({ creations: c.creations }), await u.setApi({
      key: i,
      value: {
        total: c.jumlah,
        totalPage: c.page,
        creationIds: c.creations.map((l) => l.Pblr)
      }
    }), {
      data: c.creations,
      total: c.jumlah,
      totalPage: c.page,
      page: t
    };
  },
  deleteCreation: async ({ Pblr: t }) => (await p.deleteCreation({ Pblr: t }).catch((e) => {
    console.log("Error in deleteCreation", e);
  }), f && await u.deleteCreation({ Pblr: t }).catch((e) => {
    console.log("Error in deleteCreation", e);
  }), !0),
  renameCreation: async ({ Pblr: t, name: e }) => (await p.renameCreation({ Pblr: t, name: e }).catch((a) => {
    console.log("Error in renameCreation", a);
  }), f && await u.renameCreation({ Pblr: t, name: e }).catch((a) => {
    console.log("Error in renameCreation", a);
  }), !0)
};
export {
  u as IDBServices,
  h as Services
};
