import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  helperState,
  setMainLoader as setMainLoaderAction,
  setProgressLoader as setProgressLoaderAction,
} from '../../../store/reducers/helperReducer';
import Loader from '.';

function MainLoader() {
  const { mainLoader, progressLoader } = useSelector(helperState);
  const dispatch = useDispatch();
  const { pathname } = useRouter();

  const setMainLoader = (value) => {
    dispatch(setMainLoaderAction(value));
  };

  const setProgressLoader = (value, message = 'Found missing projects, restoring') => {
    dispatch(setProgressLoaderAction({ show: value, message }));
  };

  useEffect(() => {
    setMainLoader(false);

    if (typeof window !== 'undefined') {
      window.startMainLoader = window.startMainLoader || (() => setMainLoader(true));
      window.stopMainLoader = window.stopMainLoader || (() => setMainLoader(false));
      window.startProgressLoader = window.startProgressLoader || ((message = 'Found missing projects, restoring') => setProgressLoader(true, message));
      window.stopProgressLoader = window.stopProgressLoader || (() => setProgressLoader(false));
    }
  }, []);

  useEffect(() => {
    setMainLoader(false);
  }, [pathname]);

  const [dots, setDots] = useState(0);

  useEffect(() => {
    if (progressLoader.show) {
      window.progressLoaderInterval = setInterval(() => {
        setDots((dotsValue) => (dotsValue + 1) % 4);
      }, 500);
    } else if (window.progressLoaderInterval) {
      clearInterval(window.progressLoaderInterval);
    }
  }, [progressLoader.show]);

  return (
    <>
      <Loader show={mainLoader || false} />
      <Loader show={progressLoader.show} message={`${progressLoader.message || 'Found missing projects, restoring'}${Array.from({ length: dots }).map(() => '.').join('')}`} />
    </>
  );
}

export default MainLoader;
