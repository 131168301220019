/* eslint-disable react/no-danger */
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal, ThemeWrapper } from 'assemblr-ui';
import { Select, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import nookies from 'nookies';
import { setUserMeta } from '../../store/reducers/authReducer';
import OnBoardingServices from '../../services/OnBoardingServices';
import AppServices from '../../services/AppService';
import useAuth from '../../hooks/useAuth';
import countryList from '../../datasources/countryList';
import { useRouter } from 'next/router';

export const getSelectedCountry = () => nookies.get().selected_country;
export const setSelectedCountry = (country) => nookies.set({}, 'selected_country', country?.toUpperCase?.(), {
  maxAge: 30 * 24 * 60 * 60,
  path: '/',
  ...(window.location.hostname.includes('assemblrworld.com') && { domain: 'assemblrworld.com' }),
});

export const removeSelectedCountry = () => {
  nookies.destroy({}, 'selected_country', {
    path: '/',
    ...(window.location.hostname.includes('assemblrworld.com') && { domain: 'assemblrworld.com' }),
  });
};

const { Option } = Select;

function CountryListener() {
  const { pathname } = useRouter();

  if (['/onboardings', 'profile-completion'].some(_pathname => pathname?.includes(_pathname))) {
    return null
  }

  const [show, setShow] = useState(false);
  const [selectedCountry, _setSelectedCountry] = useState(getSelectedCountry());
  const { user, userMeta: metaData } = useAuth();

  const [detectedCountry, setDetectedCountry] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!detectedCountry) {
      AppServices.getGeoIp()
        .then(({ country }) => {
          setDetectedCountry(countryList.find((item) => item.code.toLowerCase() === country));
        });
    }
  }, [detectedCountry]);

  useEffect(() => {
    if (metaData?.hasFetch && metaData?.country_code && detectedCountry?.code) {
      if (metaData?.country_code === detectedCountry?.code && !selectedCountry) {
        _setSelectedCountry(metaData?.country_code);
        setSelectedCountry(metaData?.country_code);
      } else if (metaData?.country_code !== detectedCountry?.code && !selectedCountry) {
        _setSelectedCountry(detectedCountry?.code);
        setShow(true);
      } else if (metaData?.country_code && !!selectedCountry && metaData?.country_code !== selectedCountry) {
        setTimeout(() => {
          OnBoardingServices().updateOrCreateRoleAndIndustry({ country_code: getSelectedCountry()?.toUpperCase?.() })
            .then(() => {
              dispatch(setUserMeta({ ...metaData, country_code: getSelectedCountry()?.toUpperCase?.() }));
            });
        }, 500);
      }
    }

    console.log({ metaData, detectedCountry, selectedCountry });
  }, [metaData, detectedCountry, selectedCountry]);

  useEffect(() => {
    if (!user && !getSelectedCountry() && detectedCountry) {
      _setSelectedCountry(detectedCountry?.code);
      setShow(true);
    }
  }, [user, detectedCountry]);

  const handleSaveCountry = () => {
    setSelectedCountry(selectedCountry);
    setShow(false);
    if (user) {
      OnBoardingServices().updateOrCreateRoleAndIndustry({ country_code: selectedCountry })
        .then(() => {
          dispatch(setUserMeta({ ...metaData, country_code: selectedCountry }));
        });
    }
  };

  return (document.getElementById('__next')
    && createPortal(
      <ThemeWrapper>
        <Modal
          visible={show}
          dissmissable={false}
          containerClassName="!bg-[#EEF0F4] md:!max-h-fit md:!max-w-[480px] !z-50"
          bodyClassName="!min-h-64 h-fit flex flex-col"
        >
          {detectedCountry?.name ? (
            <p
              className="text-[18px]"
            >It appears you are from <b>{detectedCountry?.name}</b>. If this is incorrect, please fix it for a better experience
            </p>
          )
            : <p className="text-[18px]">Please select country</p>}
          <Select
            showSearch
            optionFilterProp="label"
            className="font-semibold mt-6"
            popupClassName="z-[2147483005]"
            size="large"
            defaultValue={selectedCountry}
            value={selectedCountry}
            onChange={(val) => {
              _setSelectedCountry(val);
            }}
          >
            {countryList.map((v) => (
              <Option
                value={v.code}
                label={v.name}
                key={v.code}
                className="!p-0"
              >
                <Space>
                  <span role="img" aria-label={v.name}>
                    {v.emoji}
                  </span>
                  {v.name}
                  {`(${v.code})`}
                </Space>
              </Option>
            ))}
          </Select>
          <p
            className="text-[12px] italic !mt-2"
          >Offers and prices will be customized based on this country selection</p>
          <div className="flex justify-start mt-10">
            <button
              type="button"
              onClick={handleSaveCountry}
              className="bg-[#205FF4] rounded-lg text-white px-5 py-2 text-[14px] font-semibold !h-10"
            >
              Save country preference
            </button>
          </div>
        </Modal>
      </ThemeWrapper>,
      document.getElementById('__next'),
    ));
}

export default CountryListener;
