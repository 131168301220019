import { META_CACHE_KEY_BY_UID, clearCacheByKeys } from '../utils/cacheRoutes';
import apiService from './apiService';

const UserServices = {
  getMeta: async (uid) => {
    const { data } = await apiService.get(`/user-profile/meta/detail/${uid}`);
    return data;
  },
  getCurrency: async (uid, _userMeta = null) => {
    console.log({ uid, _userMeta });
    try {
      if (!uid) throw new Error('uid is required');
      clearCacheByKeys(META_CACHE_KEY_BY_UID);
      let userMeta = _userMeta;
      if (!(_userMeta.currency_code || _userMeta.country_code)) {
        userMeta = await UserServices.getMeta(uid);
      }
      console.log({ _userMeta: userMeta });

      if (userMeta?.currency_code) {
        return {
          code: userMeta.currency_code,
          hasSet: true,
        };
      }

      if (userMeta?.country_code) {
        return {
          code: userMeta.country_code === 'ID' ? 'IDR' : 'USD',
          hasSet: false,
        };
      }

      return {
        code: navigator.language === 'id' ? 'IDR' : 'USD',
        hasSet: false,
      };
    } catch {
      return {
        code: navigator.language === 'id' ? 'IDR' : 'USD',
        hasSet: false,
      };
    }
  },
  putCurrency: async (currencyCode) => {
    const { data } = await apiService.post('/user-profile/currency/', {
      currency: currencyCode,
    });
    return {
      code: data.currency_code,
      hasSet: true,
    };
  },
  getFeaturesTotal: async (uid = ':uid') => {
    const { data } = await apiService.get(`/v2/features/total/${uid}`);
    return data;
  },
};

export default UserServices;
