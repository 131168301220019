import { paymentUrl } from '../config/getdomain';
import apiService from './apiService';
import ssApiService from './serverSides/ssApiService';

const PaymentServices = {
  getLastUnpaidOrder: async () => {
    const { data } = await apiService.get('/payments/last-checkout/');
    return data;
  },
  getPaymentDetail: async ({ orderId }) => {
    const { data } = await apiService.get(`/payments/detail/${orderId}`);
    return data;
  },
  getPaymentStatus: async ({ invoiceId }) => {
    const { data } = await apiService.get(`/payments/check-status/${invoiceId}`);
    return data;
  },
  getUserPlan: async (uid) => {
    try {
      const { data } = await ssApiService.get(`/features/active/${uid}`);
      return (data?.active_plan || {});
    } catch (err) {
      console.log({ err });
      return null;
    }
  },
  getActiveFeatures: async () => {
    const { data } = await apiService.get('/features/active/:uid');
    return data;
  },
  renewalPayment: async ({ invoice, method = 'midtrans' }) => {
    const { data } = await apiService.post(`/payments/renewal-${method === 'midtrans' ? 'midtrans' : 'stripe'}/`, {
      invoice,
      success_url: `${paymentUrl()}/status/`,
      cancel_url: `${paymentUrl()}/status/`,
    });
    return data;
  },
  cancelSubs: async ({ invoice }) => {
    try {
      const { status } = await apiService.post('/payments/cancel-stripe/', { invoice });
      return status;
    } catch (err) {
      console.log({ err }, 'cancelSubs');
      return null;
    }
  },
  cancelPlaystore: async ({ invoice }) => {
    try {
      const { status } = await apiService.post('/payments/cancel-playstore/', { invoice });
      return status;
    } catch (err) {
      console.log({ err }, 'cancel playstore');
      return null;
    }
  },
  getExtraProjectPlans: async (uid) => {
    try {
      const { data } = await ssApiService.get(`/features/active/${uid}/extra-project`);
      return (data);
    } catch (err) {
      console.log({ err });
      return []
    }
  }
};

export default PaymentServices;
