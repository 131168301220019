/* eslint-disable react/react-in-jsx-scope */
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useMemo } from 'react';
import metadatas from '../../../store/metadata.json';

function Seo(initialSeo = {}) {
  const {
    title, description, ogImage, twitterImage, image,
  } = initialSeo;
  const { asPath, pathname, query } = useRouter();

  const defaultSeo = useMemo(() => {
    const metadata = metadatas.find((metadata) => metadata.url === asPath || metadata.url === pathname);
    if (metadata && query?.slug) {
      Object.keys(metadata).forEach((key) => {
        if (typeof metadata[key] === 'string') {
          metadata[key] = metadata[key].replace('{slug}', query.slug);
        }
      });
    }

    return {
      ...metadata,
      image: metadata?.image || 'https://asset.asblr.app/opengraphs/explore.jpg',
    } || {
      title: 'Assemblr Studio | Create 3D & AR on the Web',
      description: 'Make free 3D & AR experiences on your web browser with Assemblr Studio. No coding, simply drag-and-drop premade 2D & 3D assets and other elements available.',
      image: 'https://asset.asblr.app/opengraphs/explore.jpg',
    };
  }, [asPath]);

  const seo = useMemo(() => ({
    title: title || defaultSeo?.title,
    description: description || defaultSeo?.description,
    ogImage: ogImage || image || defaultSeo?.ogImage || defaultSeo?.image,
    twitterImage: twitterImage || image || defaultSeo?.twitterImage || defaultSeo?.image,
  }), [title, defaultSeo?.title, defaultSeo?.description, defaultSeo?.ogImage, defaultSeo?.twitterImage, description, ogImage, image, twitterImage]);

  return (
    <Head>
      {/* <!-- Twitter --> */}
      <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
      <meta name="twitter:card" content="player" key="twitter:card" />
      <meta name="twitter:title" content={`${seo.title}`} key="twitter:title" />
      <meta name="twitter:site" content="Assemblr App" key="twitter:site" />
      <meta name="twitter:player:width" content="480" key="twitter:player:width" />
      <meta name="twitter:player:height" content="480" key="twitter:player:height" />
      <meta
        name="twitter:image"
        content={seo?.twitterImage}
        key="twitter:image"
      />
      <meta
        name="twitter:url"
        content={`"https://studio.assemblrworld.com${asPath}"`}
        key="twitter:url"
      />
      <meta
        name="twitter:description"
        content={seo.description}
        key="twitter:description"
      />

      {/* <!-- Primary Meta Tags --> */}
      <title>{seo.title}</title>
      <meta name="title" content={`${seo.title}`} key="title" />
      <meta
        name="description"
        content={seo.description}
        key="description"
      />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" key="og:type" />
      <meta
        property="og:url"
        content={`"https://studio.assemblrworld.com${asPath}"`}
        key="og:url"
      />
      <meta property="og:title" content={`${seo.title}`} key="og:title" />
      <meta
        property="og:description"
        content={seo.description}
        key="og:description"
      />
      <meta property="og:image" content={seo?.ogImage} key="og:image" />
      <meta property="og:image:width" content="560" />
      <meta property="og:image:height" content="316" />

      {/* Iframely */}
      <meta property="iframely:title" content={`${seo.title}`} />
      <meta property="iframely:description" content={seo.description} />
      <meta property="iframely:image" content={seo?.ogImage} />
    </Head>
  );
}

export default Seo;
