import {
  getMessaging, getToken, isSupported, onMessage,
} from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  child, getDatabase, ref, set,
} from 'firebase/database';
// import {
//   getRemoteConfig,
//   fetchAndActivate,
//   getAll,
// } from 'firebase/remote-config';
import {
  addNotification,
  removeNotification as removeNotificationById,
  // setRemoteConfigs,
} from '../store/actions/uiActions';
import { app as firebaseInstance } from '../config/firebaseInit';
import useAuth from './useAuth';

const fcmCertificate = process.env.NEXT_PUBLIC_WEB_PUSH_CERTIFICATE;

const db = getDatabase();

const useFirebase = () => {
  const [app, setApp] = useState(null);
  const [messaging, setMessaging] = useState(null);
  // const [remoteConfig, setRemoteConfig] = useState(null);
  const { notifications, remoteConfigs } = useSelector(({ ui }) => ui);

  const dispatch = useDispatch();

  useEffect(() => {
    setApp(firebaseInstance);
  }, []);

  // useEffect(() => {
  //   if (remoteConfig) {
  //     remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
  //     fetchAndActivate(remoteConfig).then((activated) => {
  //       if (activated) {
  //         const configs = getAll(remoteConfig);
  //         dispatch(setRemoteConfigs(configs));
  //       } else {
  //         console.log('Config not activated');
  //       }
  //     }).catch((error) => {
  //       console.log('Error fetching and activating config', error);
  //     });
  //   }
  // }, [remoteConfig]);

  useEffect(() => {
    if (app && isSupported()) {
      try {
        const messagingInstance = getMessaging(app);
        setMessaging(messagingInstance);
      } catch (error) {
        console.log(`Error getting messaging instance: ${error?.message || error}`);
      }
    }
    // const remoteConfigInstance = getRemoteConfig(app);
    // setRemoteConfig(remoteConfigInstance);
  }, [app]);

  const { user } = useAuth();

  useEffect(() => {
    if (messaging && user?.uid) {
      if ('Notification' in window) {
        Notification.requestPermission().then(async (permission) => {
          if (permission === 'granted') {
            const token = await getToken(messaging, { vapidKey: fcmCertificate, forceNewToken: true });
            localStorage.setItem('fcmToken', token);
            if (user.uid) {
              await set(child(ref(db), `WebUserMessagingTokens/${user.uid}`), token);
            }
          } else {
            console.log('Unable to get permission to notify.');
          }
        })
          .catch((error) => {
            console.log('Error getting permission to notify.', error);
          });
      }

      onMessage(messaging, (payload) => {
        dispatch(addNotification({
          ...payload.notification,
          id: payload.messageId,
          icon: 'https://studio.assemblrworld.com/assets/icons/assemblr_only_logo.svg',
          target_url: payload.data.target_url,
        }));
      });
    }
  }, [dispatch, messaging, user?.uid]);

  const sendNotification = async ({
    title, body, fcm_token: fcmToken, password = '', uid = user?.uid,
  }) => {
    const currentToken = localStorage.getItem('fcmToken');
    const payload = {
      title,
      body,
      fcm_token: fcmToken || currentToken,
      password,
      uid,
    };
    const response = await fetch('/api/fcm/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    console.log('data : ', data);
  };

  const removeNotification = (id) => {
    dispatch(removeNotificationById(id));
  };

  return {
    app, messaging, sendNotification, notifications, removeNotification, remoteConfigs,
  };
};

export default useFirebase;
