export const NameGenerator = () => {
    const nounList = ["dog", "goat", "pig", "sheep", "cattle", "cat", "chicken", "donkey", "duck", "buffalo", "bee", "camel", "horse", "moth", "pigeon", "goose", "yak", "camel", "alpaca", "owl", "ferret", "dove", "turkey", "goldfish", "rabbit", "koi", "canary", "finch", "mouse", "rat", "fox", "hedgehog", "guppy", "swan", "squirrel", "cow", "hamster", "python", "cockatoo", "gecko", "parrot", "hawk", "boar", "otter", "weasel", "chipmunk", "turtle", "quail", "gecko", "rhinoceros", "crab", "bat", "meerkat", "piranha", "jellyfish", "octopus", "snail", "squid"]
    const adjectiveList = ["golden", "wonderful", "alive", "able", "intelligent", "known", "popular", "environmental", "emotional", "civil", "aware", "foreign", "recent", "unusual", "similar", "huge", "strong", "strict", "actual", "obvious", "odd", "important", "rare", "various", "responsible", "serious", "acceptable", "traditional", "suitable", "tall", "curious", "consistent", "different", "realistic", "confident", "latter", "hungry", "comprehensive", "global", "useful", "successful", "careful", "capable", "efficient", "southern", "former", "visible", "typical", "reasonable", "substantial", "sufficient", "informal", "sudden", "mad", "electrical", "nice", "impossible", "impressive", "wooden", "logical", "competitive", "accurate", "anxious", "remarkable", "automatic", "distinct", "interesting", "basic", "united", "massive", "available", "obviously", "numerous", "healthy", "tiny", "relevant", "nervous", "lonely", "dangerous", 
    "silver"]

    const randomIndex1 = Math.floor((Math.random() * nounList.length) + 0);
    const randomIndex2 = Math.floor((Math.random() * adjectiveList.length) + 0);
    const randomName = adjectiveList[randomIndex2]+"-"+nounList[randomIndex1]
    return randomName
}
