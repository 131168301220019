import { useEffect } from 'react';
import {
  useDispatch,
} from 'react-redux';
import {
  // useFeatureFlagEnabled,
  // useFeatureFlagVariantKey,
  usePostHog,
} from 'posthog-js/react';
import useAuth from './useAuth';
import { setPopUpBanners, setPromoBanners, setTallySurveys } from '../store/reducers/posthogReducer';
import {
  evaluateNotificationByFlags, setDelayBetweenNotification, setDelayUnpaidInvoiceNotification,
  // notificationState,
  setFourContentType,
} from '../store/reducers/notificationReducer';
import { setEnableAds } from '../store/reducers/appReducer';

const useCustomPosthog = () => {
  const { user } = useAuth();
  const _posthog = usePostHog();
  const dispatch = useDispatch();
  // const is4ContentEnabled = useFeatureFlagEnabled('pengenalan-4-tipe-konten'); //pakai ini karena kalau pakai onFeatureFlags kepanggilnya 2x, jadi event yg kekirim ke mixpanel jadi 2x juga
  // const fourContentVariant = useFeatureFlagVariantKey('pengenalan-4-tipe-konten'); //dipakai buat trigger useeffect aja
  // const { fourContentType } = useSelector(notificationState);

  // const send4ContentMixpanel = (response) => {
  //   mixpanel.track('pengenalan-4-tipe-konten', {
  //     response,
  //     ip: ip.address(),
  //     distinct_id: user.uid,
  //   });
  // };

  useEffect(() => {
    if (_posthog && user) {
      const lastIdentify = localStorage.getItem('lastIdentify');

      const userProps = {
        email: user.email,
      };

      const fourContentDate = new Date('2023-08-29').getTime();

      if (user.createdAt > fourContentDate) {
        userProps.user_creation_time = new Date(Number(user.createdAt)).toISOString();
      }

      if (lastIdentify !== user.uid) {
        _posthog.identify(user.uid, userProps);
        localStorage.setItem('lastIdentify', user.uid);
      }

      const currentDate = new Date().toLocaleDateString({
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });

      const lastSession = localStorage.getItem('lastSession');
      if (lastSession !== `${user.uid}-${currentDate}`) {
        _posthog.capture('session', {
          user_id: user.uid,
          email: user.email,
        });
        localStorage.setItem('lastSession', `${user.uid}-${currentDate}`);
      }

      _posthog.onFeatureFlags((flags) => {
        dispatch(evaluateNotificationByFlags(flags || []));
        const promoBannerKeys = flags.filter((flag) => flag.includes('promo_banner'));
        const promoBanners = promoBannerKeys.map((promoBannerKey) => {
          if (!_posthog.isFeatureEnabled(promoBannerKey)) return false;
          const payload = _posthog.getFeatureFlagPayload(promoBannerKey);
          return {
            ...payload,
            id: payload.id || promoBannerKey,
            featureFlag: promoBannerKey,
          };
        });
        dispatch(setPromoBanners(promoBanners?.filter(Boolean)));

        const popUpBannerKeys = flags.filter((flag) => flag.includes('promo_popup'));
        const popUpBanners = popUpBannerKeys.map((popUpBannerKey) => {
          if (!_posthog.isFeatureEnabled(popUpBannerKey)) return false;
          const payload = _posthog.getFeatureFlagPayload(popUpBannerKey);
          return {
            ...payload,
            id: payload.id || popUpBannerKey,
            featureFlag: popUpBannerKey,
          };
        });

        const validPopUpBanners = popUpBanners.filter((popUpBanner) => {
          if (!popUpBanner?.id) return false;
          if (!popUpBanner?.content) return false;
          if (!popUpBanner?.cooldown_time) return false;
          if (!popUpBanner?.actions) return false;
          return true;
        });
        dispatch(setPopUpBanners(validPopUpBanners));

        if (_posthog.isFeatureEnabled('pengenalan-4-tipe-konten', { send_event: false })) {
          const initialContent = _posthog.getFeatureFlag('pengenalan-4-tipe-konten');
          dispatch(setFourContentType(initialContent));
        }

        // const unpaidInvoiceType = _posthog.getFeatureFlag('unpaid_invoice_notification_type', { send_event: false })
        // dispatch(setUnpaidInvoiceType(unpaidInvoiceType || 'header_banner'))

        const delayBetweenPromoNotification = _posthog.getFeatureFlagPayload('delay_between_promo', { send_event: false });

        dispatch(setDelayBetweenNotification(delayBetweenPromoNotification || 5 * 1000));

        const delayUnpaidInvoiceNotification = _posthog.getFeatureFlagPayload('delay_invoice_notification', { send_event: false });
        dispatch(setDelayUnpaidInvoiceNotification(delayUnpaidInvoiceNotification || 60 * 60 * 24 * 1000));

        const tallySurveyKeys = flags.filter((flag) => flag.includes('tally_survey'));
        const tallySurveys = tallySurveyKeys.map((tallySurveyKey) => {
          if (!_posthog.isFeatureEnabled(tallySurveyKey)) return false;
          const payload = _posthog.getFeatureFlagPayload(tallySurveyKey);
          return {
            ...payload,
            id: payload.id || tallySurveyKey,
            featureFlag: tallySurveyKey,
          };
        });

        const validTallySurveys = tallySurveys.filter((tallySurvey) => {
          if (!tallySurvey?.id) return false;
          return true;
        });
        dispatch(setTallySurveys(validTallySurveys));

        // if (_posthog.isFeatureEnabled('adsense_studio_creation', { send_event: false })) {
        //   dispatch(setEnableAds(true));
        // } else {
        //   dispatch(setEnableAds(false));
        // }
      });
    } else if (_posthog) {
      try {
        _posthog.reset();
      } catch {
        console.log('error resetting posthog');
      }
      localStorage.removeItem('lastIdentify');
    }
  }, [user, _posthog, dispatch]);

  // useEffect(() => {
  //   if (is4ContentEnabled && user?.uid && (fourContentType === fourContentVariant)) {
  //     dispatch(setFourContentType(fourContentVariant));
  //   }
  // }, [user, fourContentType, fourContentVariant]);
};

export default useCustomPosthog;
