/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { paymentUrl } from '../../../config/getdomain';
import styles from './IframePopup.module.scss';
import { currentDistinctId } from '../../../hooks/useAnalytics';
import { loginPopupAnalyticTracking } from '../../controllers/analytic_tracking';
import { setLogin } from '../../../store/actions/popupActions';
import { appState, setIframePopup } from '../../../store/reducers/appReducer';
import { authState, setUserPlan } from '../../../store/reducers/authReducer';
import PaymentServices from '../../../services/PaymentServices';
import Versioning from '../../../utils/Versioning';
import { clearCacheByKeys } from '../../../utils/cacheRoutes';

function IframePopup() {
  const dispatch = useDispatch();
  const {
    query, isReady, pathname, push, replace,
  } = useRouter();

  const showLogin = useSelector((state) => state.popup.login);
  const { iframePopup, currency: { code } } = useSelector(appState);
  const { token, userPlan, userData } = useSelector(authState);

  const isSelfReady = useRef(false);

  const getIframe = async () => {
    const param = {
      embed: true,
      close_placement: 'right',
      currency: code,
      ...query,
      distinctId: currentDistinctId(),
      isSubscribe: !!userPlan?.plans_name,
      token: token || '',
    };

    if (query?.content === 'checkout') {
      if (!(['basic', 'medium', 'premium'].includes(query.group) && !!userPlan?.plans_name)) {
        dispatch(setIframePopup({ open: true, url: `${paymentUrl()}/new/checkout?${new URLSearchParams(param).toString()}` }));
      } else {
        dispatch(setIframePopup({ open: false, url: '' }));
        replace({ pathname: window.location.pathname }, '', { shallow: true, scroll: false });
      }
    }
    if (query?.content === 'success') {
      dispatch(setIframePopup({ open: true, url: `${paymentUrl()}/Success?${new URLSearchParams(param).toString()}` }));
    }
    if (query?.content === 'redeem') {
      dispatch(setIframePopup({ open: true, url: `${paymentUrl()}/Redeem?${new URLSearchParams(param).toString()}` }));
    }
    if (query?.content === 'failed') {
      dispatch(setIframePopup({ open: true, url: `${paymentUrl()}/Failed?${new URLSearchParams(param).toString()}` }));
    }
    if (query?.content === 'status') {
      dispatch(setIframePopup({ open: true, url: `${paymentUrl()}/status/${query?.invoice}?${new URLSearchParams(param).toString()}` }));
    }
  };

  const fetchUserPlan = async (uid, redirect = true) => {
    const data = await PaymentServices.getUserPlan(uid);
    dispatch(setUserPlan(data));

    if (data && redirect) {
      dispatch(setIframePopup({ open: false, url: '' }));
      replace((Versioning.ONE_TIME_CREATION()) ? '/account/invoices' : '/account/plan');
    }
  };

  useEffect(() => {
    if (isReady && isSelfReady.current) {
      getIframe();
    }
  }, [isReady, query, token, showLogin, code, isSelfReady.current]);

  const messageListener = async (e) => {
    if (e.data === 'show popup login' || e.data.id === 'show popup login') {
      const newParam = {
        ...(e.data.voucher) && { voucher: e.data.voucher },
        ...(e.data.scheme) && { scheme: e.data.scheme },
        ...(e.data.method) && { method: e.data.method },
        ...(e.data.model) && { model: e.data.model },
      };
      if (isReady) push({ pathname, query: { ...query, ...newParam } }, '', { shallow: false });
      dispatch(setLogin('login'));
      if (query?.content === 'checkout') loginPopupAnalyticTracking('purchase_btn');
      if (query?.content === 'redeem') loginPopupAnalyticTracking('redeem_btn');
    }
    if (e.data === 'close popup' || e.data === 'close popup redeem') {
      dispatch(setIframePopup({ open: false, url: '' }));

      await clearCacheByKeys('/features/total/')
      sessionStorage.removeItem('triggerTutorial');
      if ((window.location.pathname?.toLocaleLowerCase().includes('creation')
        || Versioning.ONE_TIME_CREATION())
        && localStorage.getItem('UUID')) {
        fetchUserPlan(localStorage.getItem('UUID'), false);
      }
      if (window.location.pathname?.includes('projects') || window.location.pathname?.includes('/page/') || window.location.pathname?.includes('/explore')) {
        replace({ pathname: window.location.pathname }, '', { shallow: true });
      }
    }

    if (e.data === 'refetch-user-plan') {
      fetchUserPlan(userData?.FirebaseID);
    }

    if (e.data.type === 'invoice-status') {
      replace({ pathname: window.location.pathname }, '', { shallow: false, scroll: false });
    }
  };

  useEffect(() => {
    dispatch(setIframePopup({ open: false, url: '' }));
    isSelfReady.current = true;
  }, []);

  useEffect(() => {
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [query]);

  useEffect(() => {
    if (iframePopup.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [iframePopup.open]);

  useEffect(() => {
    if (iframePopup.url) {
      const url = new URL(iframePopup.url);
      if (url.searchParams.get('token')?.length === 0) {
        url.searchParams.delete('token');
      }

      if (!url.searchParams.get('token') && token) {
        url.searchParams.append('token', token);
      }

      if (iframePopup.url !== url.toString()) {
        dispatch(setIframePopup({
          ...iframePopup,
          url: url.toString(),
        }));
      }
    }
  }, [iframePopup.url, token]);

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && token) {
      ref.current?.contentWindow?.postMessage?.({ id: 'set-token', token: token }, '*');
    }
  }, [token, ref.current]);

  return iframePopup.open && !showLogin && (
    <iframe
      ref={ref}
      key={iframePopup.url}
      title="payment-pwa"
      name="payment-iframe"
      id="payment-pwa"
      className={`embed-payment-astu ${styles.redeem_iframe}`}
      style={{
        overflow: 'scroll',
      }}
      src={iframePopup.url}
      onLoad={() => {
        ref.current?.contentWindow?.postMessage?.({ id: 'set-token', token: token }, '*');
      }}
    />
  );
}

export default IframePopup;
