import { create } from "zustand";
import { persist } from "zustand/middleware";
import userAgent from "./userAgent";

export const TIERED_SUBSCRIPTION = 1;
export const ONE_TIME_CREATION = 2;

const store = create(persist(set => ({
    version: TIERED_SUBSCRIPTION,
    setVersion: (version) => set({ version }),
}), {
    name: "versioning",
}));

class Versioning {

    static get() {
        return store.getState().version;
    }

    static set(version) {
        store.getState().setVersion(version);
    }

    static ONE_TIME_CREATION() {
        return store.getState().version === ONE_TIME_CREATION;
    }

    static TIERED_SUBSCRIPTION() {
        return store.getState().version === TIERED_SUBSCRIPTION;
    }
}

export const versionByEmail = (email, createdAt = null) => {
    if (email?.split?.('@')?.[1]?.includes('belajar.id')) {
        return TIERED_SUBSCRIPTION;
    }

    if (createdAt) {
        try {
            const date = new Date(Number(createdAt));
            if (date < new Date('2024-06-28')) {
                return TIERED_SUBSCRIPTION
            }
        } catch (e) {
            console.error(e);
        }
    }

    if (userAgent.isMobile.IOS() && userAgent.isMobile.Assemblr()) {
        return TIERED_SUBSCRIPTION;
    }

    return ONE_TIME_CREATION;
}

export default Versioning;