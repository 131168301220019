import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import dataReducer from './dataReducer';
import popupReducer from './popupReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';
import onboardingReducer from './onboardingReducer';
import creationReducer from './creationReducer';
import authReducer from './authReducer';
import helperReducer from './helperReducer';
import appReducer from './appReducer';
import { asblrApi } from '../rtk/asblrApi';
import posthogReducer from './posthogReducer';
import notificationReducer from './notificationReducer';
import paymentReducer from './paymentReducer';
import temporaryReducer from './temporaryReducer';
import libraryReducer from './libraryReducer';

export const encryptTransformConfig = encryptTransform({
  secretKey: process.env.NEXT_PUBLIC_SECRET_KEY,
  onError() {
    storage.keys().then((keys) => {
      keys.forEach((key) => {
        if (key.indexOf('persist:') === 0) {
          storage.removeItem(key);
        }
      });
      window.location.reload();
    });
  },
});

export const transforms = process.env.NEXT_PUBLIC_DEBUG_MODE === 'TRUE' ? [] : [encryptTransformConfig];

const authPersistConfig = {
  key: 'auth',
  storage,
  transforms,
};

const creationsPersistConfig = {
  key: 'creations',
  storage,
  transforms,
};

const helperPersistConfig = {
  key: 'helper',
  storage,
  transforms,
};

// const appPersistConfig = {
//   key: 'app',
//   storage,
//   transforms,
// };

const notificationPersistConfig = {
  key: 'notification',
  storage,
  transforms,
};

const paymentReducerConfig = {
  key: 'payment',
  storage,
  transforms,
};

const temporaryPersistConfig = {
  key: 'temporary',
  storage,
  transforms,
};

const appPersistConfig = {
  key: 'app',
  storage,
  transforms,
  whitelist: ['listenterpriseuids'],
};

export default combineReducers({
  userData: persistReducer(authPersistConfig, userReducer),
  popup: popupReducer,
  data: dataReducer,
  ui: uiReducer,
  onboarding: onboardingReducer,
  creation: persistReducer(creationsPersistConfig, creationReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  helper: persistReducer(helperPersistConfig, helperReducer),
  app: persistReducer(appPersistConfig, appReducer),
  [asblrApi.reducerPath]: asblrApi.reducer,
  posthog: posthogReducer,
  notification: persistReducer(notificationPersistConfig, notificationReducer),
  payment: persistReducer(paymentReducerConfig, paymentReducer),
  temporary: persistReducer(temporaryPersistConfig, temporaryReducer),
  library: libraryReducer,
});
