import { getSelectedCountry } from '../components/CountryListener';
import AppServices from '../services/AppService';
import { store } from '../store/store';
import userAgent from './userAgent';

const Segmentations = {
  inCriteriaConfigCheck: ({
    key, targetKey, configs, userMeta, userPlan, currency, user, userData,
  }) => {
    if (!key.includes(targetKey) || !userMeta) return false;
    const config = configs[key]?.value || {};
    const {
      start_date: startDate = null,
      end_date: endDate = null,
      conditions: {
        app = null,
        country = null,
        platform = null,
        currency: configCurrency = null,
        paid = null,
        whitelist = null,
        segment_membership = null,
      } = {},
    } = config;

    const now = Date.now();
    const start = startDate ? new Date(startDate).getTime() : null;
    const end = endDate ? new Date(endDate).getTime() : null;

    const userCountry = getSelectedCountry() ? getSelectedCountry().toLowerCase() : null;

    if (start && end && now >= start && now <= end) {
      if (app && app !== 'studio') return false;
      if (country) {
        const exclude = country.startsWith('!');
        const countryCodes = exclude
          ? country.slice(1).split(',').map((c) => c.trim().toLowerCase())
          : country.split(',').map((c) => c.trim().toLowerCase());

        if (exclude && countryCodes.includes(userCountry)) return false;
        if (!exclude && !countryCodes.includes(userCountry)) return false;
      }
      if (configCurrency && configCurrency.toLowerCase() !== currency?.code?.toLowerCase()) return false;
      if (paid !== null) {
        if (paid && !userPlan?.expire_date) return false;
        if (!paid && userPlan?.expire_date) return false;
      }
      if (platform && (userAgent.isMobile.any() && platform !== 'mobile')) return false;
      if (whitelist) {
        const userProps = [user?.uid, user?.email];
        const whitelisted = Array.isArray(whitelist)
          ? whitelist.some((w) => userProps.includes(w))
          : userProps.includes(whitelist);
        if (!whitelisted) return false;
      }

      if (segment_membership) {
        if (Array.isArray(segment_membership)) {
          const userSegment = userData?.segment_membership || [];
          const isSegment = segment_membership.some((segment) => userSegment.includes(segment));

          if (!isSegment) return false;
        }
        if (typeof segment_membership === 'string') {
          const userSegment = userData?.segment_membership || [];
          if (!userSegment.includes(segment_membership)) return false;
        }
      }

      return true;
    } return false;
  },
  inCriteriaAdsConfigCheck: ({
    app = 'studio', country = null, platform = null,
  }) => {
    const { userMeta, userPlan, user } = store.getState().auth;

    const paidUser = user && userPlan?.expire_date;
    if (paidUser) return false;

    if (app && app !== 'studio') return false;
    const userCountryCode = AppServices.userGeoIp().country || userMeta?.country_code?.toLowerCase() || navigator.language?.split('-')[1]?.toLowerCase();
    if (country && userCountryCode) {
      const exclude = country.startsWith('!');
      const countryCodes = exclude
        ? country.slice(1).split(',').map((c) => c.trim().toLowerCase())
        : country.split(',').map((c) => c.trim().toLowerCase());

      if (exclude && countryCodes.includes(userCountryCode)) return false;
      if (!exclude && !countryCodes.includes(userCountryCode)) return false;
    }
    if (platform && (userAgent.isMobile.any() && platform !== 'mobile')) return false;
    return true;
  },
};

export default Segmentations;
