import mixpanel from 'mixpanel-browser';

const analyticTracking = (uuid, source, destination) => {
  window.dataLayer.push({
    event: 'lead_to_conversion',
    uuid,
    data: {
      flow_source: source,
      destination,
    },
  });

  mixpanel.track('lead_to_conversion', {
    uuid,
    flow_source: source,
    destination,
  });
};

const loginPopupAnalyticTracking = (source, data = {}) => {
  window.dataLayer.push({
    event: 'lead_to_login',
    data: {
      flow_source: source,
      ...data,
    },
  });

  mixpanel.track('lead_to_login', {
    flow_source: source,
    ...data,
  });
};

const loginActivitiesAnalyticTracking = (source) => {
  window.dataLayer.push({
    event: source,
  });

  mixpanel.track(source);
};

export {
  analyticTracking,
  loginPopupAnalyticTracking,
  loginActivitiesAnalyticTracking,
};
