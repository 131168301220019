import { useRouter } from 'next/router';
import React, {
  createContext, useContext, useState, useMemo, useEffect,
} from 'react';

const HistoryContext = createContext();

export const useHistory = () => useContext(HistoryContext);

export function HistoryProvider({ children }) {
  const [history, setHistory] = useState([]);

  const value = useMemo(() => ({ history, setHistory }), [history]);

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>;
}

export function TrackHistory() {
  const router = useRouter();
  const { setHistory } = useHistory();

  useEffect(() => {
    const handleRouteChange = (url) => {
      setHistory((prevHistory) => [...prevHistory, url]);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, setHistory]);

  return null;
}
