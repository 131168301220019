import { LEFT } from "../reducers/uiReducer";
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SET_LAYOUT_TRANSITION_DIRECTION, SET_REMOTE_CONFIGS } from "../types";

export const setLayoutTransitionDirection = (direction) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LAYOUT_TRANSITION_DIRECTION,
      payload: direction,
    });
  } catch (error) {
    dispatch({
      type: SET_LAYOUT_TRANSITION_DIRECTION,
      payload: LEFT
    });
  }
};

export const addNotification = (notification) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: notification,
    });
  } catch (error) {
    // TODO : handle error
  }
}

export const removeNotification = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_NOTIFICATION,
      payload: id,
    });
  } catch (error) {
    // TODO : handle error
  }
}

export const setRemoteConfigs = (configs) => async (dispatch) => {
  try {
    dispatch({
      type: SET_REMOTE_CONFIGS,
      payload: configs,
    });
  } catch (error) {
    // TODO : handle error
  }
}