/* eslint-disable no-eval */
import axios from 'axios';
import JSZip from 'jszip';
import CreationServices from './CreationServices';
import API_URL from '../config/API_URL';

async function downloadPBLR(downloadPath, timestamp) {
  return await new Promise((resolve) => {
    axios
      .get(`${downloadPath}?time=${timestamp}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pblr',
        },
      })
      .then(async (res) => {
        const buffer = new Uint8Array(res.data);
        resolve(buffer);
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
}

const traverseZipFolder = async (zip, folderName, format) => {
  const list = [];
  if (zip.folder(folderName) != null) {
    const folder = zip.folder(folderName);
    for (const file of Object.values(folder.files)) {
      if (
        file.name.includes(`${folderName}/`)
                && file.name !== `${folderName}/`
      ) {
        const name = file.name.replace(`${folderName}/`, '');
        const value = await file.async(format);
        list.push({
          name,
          value,
        });
      }
    }
  }
  return list;
};

async function parsePBLR(buffer) {
  return await new Promise((resolve) => {
    JSZip.loadAsync(buffer, { base64: true }).then(async (zip) => {
      const SceneSettings = JSON.parse(
        JSON.stringify(
          eval(
            `(${await zip
              .file('projectsettings/SceneSettings.asset')
              .async('string')})`,
          ),
        ),
      );

      const ObjectList = JSON.parse(
        JSON.stringify(
          eval(
            `(${await zip
              .file('projectsettings/ObjectList.asset')
              .async('string')})`,
          ),
        ),
      );

      const containPremiumLibrary = await zip.file(
        'projectsettings/PremiumObjectList.asset',
      );

      const PremiumObjectList = containPremiumLibrary
        ? JSON.parse(
          JSON.stringify(
            eval(
              `(${await zip
                .file('projectsettings/PremiumObjectList.asset')
                .async('string')})`,
            ),
          ),
        )
        : null;

      const scripts = await traverseZipFolder(zip, 'scripts', 'string');

      const scenes = await traverseZipFolder(zip, 'scenes', 'string');

      const images = await traverseZipFolder(zip, 'images', 'base64');

      const sceneInfo = SceneSettings.sceneInfos;

      const interactivity = await zip.file('interactivity.json')?.async?.('string')
      .catch(() => {
        return JSON.stringify({})
      }) || JSON.stringify({})

      resolve({
        SceneSettings,
        ObjectList,
        PremiumObjectList,
        scripts,
        sceneInfo,
        scenes,
        images,
        interactivity,
      });
    });
  });
}

async function createPBLR({
  metadata,
  PremiumObjectList,
  ObjectList,
  SceneSettings,
  scripts,
  scenes,
  images,
  interactivity,
}) {
  return await new Promise((resolve) => {
    const zip = new JSZip();
    const metaData = JSON.stringify(metadata);
    const metaDataBlob = new Blob([`\uFEFF${metaData}`], {
      type: 'text/plain;charset=utf-8',
    });
    const files = zip.file('MetaData.asset', metaDataBlob);

    if (PremiumObjectList) {
      const PremiumObjectListBlob = new Blob([`\uFEFF${JSON.stringify(PremiumObjectList)}`], {
        type: 'text/plain;charset=utf-8',
      });
      files.file(
        'projectsettings/PremiumObjectList.asset',
        PremiumObjectListBlob,
      );
    }

    const pblrVersionBlob = new Blob(['\uFEFF1'], {
      type: 'text/plain;charset=utf-8',
    });
    files.file('pblrversion', pblrVersionBlob);

    const objectListBlob = new Blob([`\uFEFF${JSON.stringify(ObjectList)}`], {
      type: 'text/plain;charset=utf-8',
    });
    files.file('projectsettings/ObjectList.asset', objectListBlob);
    const sceneSettingBlob = new Blob(
      [`\uFEFF${JSON.stringify(SceneSettings)}`],
      { type: 'text/plain;charset=utf-8' },
    );
    files.file('projectsettings/SceneSettings.asset', sceneSettingBlob);

    for (const scene of SceneSettings.sceneInfos) {
      const resultDetailSceneBlob = new Blob(
        [`\uFEFF${JSON.stringify(scene)}`],
        {
          type: 'text/plain;charset=utf-8',
        },
      );
      files.file(`scenes/${scene.fileId}`, resultDetailSceneBlob);
    }

    for (const lua of scripts) {
      const LUA_STRING = new Blob([`\uFEFF${lua.value}`], {
        type: 'text/plain;charset=utf-8',
      });
      files.file(`scripts/${lua.name}`, LUA_STRING);
    }

    for (const scene of scenes) {
      const LUA_STRING = new Blob([`\uFEFF${scene.value}`], {
        type: 'text/plain;charset=utf-8',
      });
      files.file(`scenes/${scene.name}`, LUA_STRING);
    }

    for (const image of images) {
      files.file(`images/${image.name}`, image.value, { base64: true });
    }

    files.file('interactivity.json', interactivity)

    files
      .generateAsync({ type: 'blob' })
      .then(async (blob) => {
        resolve(blob);
      })
      .catch((err) => {
        resolve(null);
      });
  });
}

async function uploadFile({ uploadPath, blob }) {
  console.log({ blob });
  return await new Promise((resolve) => {
    axios
      .post(`${API_URL}/storage/upload/`, {
        path: uploadPath,
      })
      .then((res) => {
        console.log({ res });
        const firstResult = res.data.result.fields;
        const targetURL = res.data.result.url;

        const secondParam = new FormData();
        secondParam.append('AWSAccessKeyId', firstResult.AWSAccessKeyId);
        secondParam.append('key', firstResult.key);
        secondParam.append('policy', firstResult.policy);
        secondParam.append('signature', firstResult.signature);
        secondParam.append('file', blob);

        console.log({ secondParam });

        // axios
        //   .post(targetURL, secondParam)
        //   .then(() => {
        //     resolve(true);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     resolve(false);
        //   });

        fetch(targetURL, {
          method: 'POST',
          body: secondParam,
        })
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            resolve(false);
          });
      })
      .catch((err) => {
        console.log(err);
        resolve(false);
      });
  });
}

async function cloneImage(imageUrl) {
  return await new Promise((resolve) => {
    axios
      .get(imageUrl, { responseType: 'blob' })
      .then((response) => {
        const blob = response.data;
        resolve(blob);
      })
      .catch((err) => {
        resolve(null);
      });
  });
}

async function cloneProject(ID, newID, newUserID) {
  return new Promise(async (resolve, reject) => {
    console.log('cloneProject', ID, newID, newUserID);
    const projectData = await CreationServices.getDetailCreation(ID);
    if (projectData == null) {
      reject(new Error('Project not found!'));
    }

    //DOWNLOAD TARGET PROJECT
    const downloadPath = `https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/UserProject/${projectData.HostID}/${ID}/${ID}.pblr`;
    const buffer = await downloadPBLR(downloadPath, projectData.UpdatedTime);
    if (buffer == null) {
      reject(new Error('Project not found!'));
    }

    //PARSE TARGET PROJECT
    const parsedData = await parsePBLR(buffer);
    parsedData.metadata = {
      projectId: newID,
      hostId: newUserID,
    };

    const importedImagesID = [];

    for (const scene of parsedData.scenes) {
      const sceneData = JSON.parse(JSON.stringify(eval(`(${scene.value})`)));
      for (const image of sceneData.imageData) {
        if (image.imgHash.length > 1) {
          importedImagesID.push(image.imgHash);
        }
      }
    }

    //CREATE NEW PBLR FROM TARGET PROJECT
    const blob = await createPBLR({
      ...(typeof parsedData === 'object' ? parsedData : {}),
      metadata: {
        projectId: newID,
        hostId: newUserID,
      },
      SceneSettings: parsedData.SceneSettings,
      PremiumObjectList: parsedData.PremiumObjectList,
      ObjectList: parsedData.ObjectList,
      scripts: parsedData.scripts,
      scenes: parsedData.scenes,
      images: parsedData.images,
    });
    blob.name = `${newID}.pblr`;
    //saveAs(blob);

    //UPLOAD NEW PBLR FILE TO SERVER
    const uploadPath = `UserProject/${newUserID}/${newID}/${newID}.pblr`;
    const uploaded = await uploadFile({ uploadPath, blob });

    if (uploaded) {
    //COPY ALL IMAGES FROM PROJECT
      for (const imageID of importedImagesID) {
        const imagePath = `https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/UserProject/${projectData.HostID}/${ID}/images/${imageID}.png`;

        const imageBlob = await cloneImage(imagePath);
        imageBlob.name = `${imageID}.png`;
        const newImagePath = `UserProject/${newUserID}/${newID}/images/${imageID}.png`;
        await uploadFile({
          uploadPath: newImagePath,
          blob: imageBlob,
        });
      }
      resolve(true);
    } else {
      resolve(false);
    }
    resolve(false);
  });
}

export default cloneProject;
