import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SET_LAYOUT_TRANSITION_DIRECTION, SET_REMOTE_CONFIGS } from "../types";

export const LEFT = "left";
export const RIGHT = "right";
export const UP = "up";
export const DOWN = "down";

const initialState = {
    layoutTransitionDirection: UP,
    notifications: [],
    remoteConfigs: {}
}


const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LAYOUT_TRANSITION_DIRECTION:
            return {
                ...state,
                layoutTransitionDirection: action.payload
            }
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, action.payload].reverse()
            }
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== action.payload)
            }
        case SET_REMOTE_CONFIGS:
            return {
                ...state,
                remoteConfigs: action.payload
            }
        default:
            return state;
    }
}

export default uiReducer;