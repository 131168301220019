import { GET_LOGIN, ERROR } from "../types";

export const setLogin = (props) => async (dispatch, getState) => {
  try {
    const state = getState()
    dispatch({
      type: GET_LOGIN,
      payload: props,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: "error message",
    });
  }
};