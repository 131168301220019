const Helpers = {
  uniqueObjectArray: (array, key) => array.reduce((acc, current) => {
    const x = acc.find((item) => item[key] === current[key]);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []),
  ucFirst: (string) => string?.charAt?.(0)?.toUpperCase?.() + string?.slice?.(1).toLowerCase?.(),
  promoEventLog: ({
    promoType,
    promoAction,
    featureFlag,
    key,
    appConfigMode,
  }) => ({
    eventName: `${promoType}_${promoAction}`,
    eventParams: {
      promo_id: featureFlag,
      variant_id: key,
    },
    posthog: !appConfigMode,
  }),
};

export default Helpers;
