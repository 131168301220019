import axios from 'axios';
import { getAuth } from 'firebase/auth';
import API_URL from '../config/API_URL';
import UnAuthenticatedError from '../custom-errors/UnAuthenticatedError';
import fetchAdapter from './fetchAdapter';
import revalidateCacheInterceptor from './interceptors/revalidateCacheInterceptor';
import { jwtDecode } from 'jwt-decode';

const apiService = axios.create({
  adapter: fetchAdapter,
  baseURL: API_URL,
});

const setAuthorizationHeader = (config, token) => {
  config.headers.Authorization = token;
};

const replaceUIDInURL = (config, userId) => {
  if (config.url.includes(':uid') && userId) {
    config.url = config.url.replace(':uid', userId);
  }
  return config;
};

export const handleRequest = async (config) => {
  const { currentUser } = getAuth();
  const token = await currentUser?.getIdToken?.();
  localStorage.setItem('_token', token);
  if (currentUser && token) {
    try {
      setAuthorizationHeader(config, token);

      config = replaceUIDInURL(config, currentUser?.uid || jwtDecode(token).uid);
    } catch (error) {
      console.error('Error handling request:', error);
    }
  }
  return config;
};

apiService.interceptors.request.use(handleRequest);

apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.data?.detail === 'Empty token' || error?.response?.status === 403) {
      return Promise.reject(new UnAuthenticatedError());
    }
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(revalidateCacheInterceptor);

export default apiService;
