import Versioning from "../utils/Versioning";

const paymentUrl = () => (process.env.NODE_ENV === 'production'
  ? process.env.NEXT_PUBLIC_PAYMENT_URL // production
  : 'http://localhost:3001'); // dev

const marketUrl = () => (process.env.NODE_ENV === 'production'
  ? process.env.NEXT_PUBLIC_MARKETPLACE_URL // production
  : 'http://localhost:3002'); // dev

const webUrl = () => (process.env.NODE_ENV === 'production'
  ? process.env.NEXT_PUBLIC_WEB_URL // production
  : 'http://localhost:3000'); // dev

const editorUrl = () => Versioning.ONE_TIME_CREATION() ? process.env.NEXT_PUBLIC_EDITOR_URL_NEW || 'https://new-account-rules.d37c86v1q9x1uv.amplifyapp.com' : (process.env.NODE_ENV === 'production'
  ? process.env.NEXT_PUBLIC_EDITOR_URL // production
  : 'https://localhost:3003'); // dev

const playerUrl = () => process.env.NEXT_PUBLIC_PLAYER_URL || 'https://viewer.assemblrworld.com';
const playerEduUrl = () => process.env.NEXT_PUBLIC_PLAYER_EDU_URL || 'https://viewer-edu.assemblrworld.com';

export {
  paymentUrl,
  marketUrl,
  webUrl,
  editorUrl,
  playerUrl,
  playerEduUrl,
};
