import axios from "axios";
import fetchAdapter from "../services/fetchAdapter";
import revalidateCacheInterceptor from "../services/interceptors/revalidateCacheInterceptor";
import API_URL from "./API_URL";

const instance = axios.create({
    adapter: fetchAdapter,
    baseURL: API_URL.replace('/api', ''),
})

instance.interceptors.request.use(revalidateCacheInterceptor);

export default instance; 