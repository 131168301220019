import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    unpaidInvoices: []
}

const temporarySlice = createSlice({
    name: "temporary",
    initialState,
    reducers: {
        setUnpaidInvoice: (state, action) => {
            const hasUnpaidInvoice = state.unpaidInvoices.find((invoice) => invoice.orderId === action.payload.orderId)
            if (!hasUnpaidInvoice) {
                state.unpaidInvoices.push(action.payload)
            }
        },
        removeUnpaidInvoice: (state, action) => {
            state.unpaidInvoices = state.unpaidInvoices.filter((invoice) => invoice.orderId !== action.payload)
        }
    }
})

export const temporaryState = state => state.temporary

export const { setUnpaidInvoice, removeUnpaidInvoice } = temporarySlice.actions

const temporaryReducer = temporarySlice.reducer

export default temporaryReducer