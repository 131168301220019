/* eslint-disable import/no-named-as-default-member */
/* eslint-disable dot-notation */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import axios from 'axios';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModuleSearch } from 'assemblr-ui';
import { useDispatch, useSelector } from 'react-redux';
import instance from '../config/axios';
import apiService from '../services/apiService';
import listenRequestInterceptor from '../services/interceptors/listenRequestInterceptor';
import OnBoardingServices from '../services/OnBoardingServices';
import { clearCacheByKeys, META_CACHE_KEY_BY_UID } from '../utils/cacheRoutes';
import { LogWithPrefix } from '../utils/Log';
import useAuth from './useAuth';
import { appState, asyncGetListApp, setIframePopup } from '../store/reducers/appReducer';
import PaymentServices from '../services/PaymentServices';
import { asyncGetUserMeta, authState, setUserPlan } from '../store/reducers/authReducer';
import { setLogin } from '../store/actions/popupActions';
import useAnalytics, { currentDistinctId } from './useAnalytics';
import useCreations from './useCreations';
import AppServices from '../services/AppService';
import { asyncGetEnterpriseUids, helperState } from '../store/reducers/helperReducer';
import Versioning from '../utils/Versioning';
import { paymentUrl } from '../config/getdomain';
import ExceededQuotaCreationError from '../custom-errors/ExceededQuotaCreationError';

const withAlwaysResolve = async (callback) => {
  try {
    await callback();
  } catch (error) {
    console.log(error);
  }
};

const withGrantedPath = async ({ grantedPaths = [], pathname, callback }) => {
  if (grantedPaths.includes(pathname)) {
    await withAlwaysResolve(callback);
  }
};

const useInitialActions = () => {
  const {
    push, pathname, isReady, asPath, replace, query: queryNext,
  } = useRouter();
  const query = new URLSearchParams(asPath.split('?')[1]); // query dari useRouter bermasalah buat kasus delay onboarding, jadi bikin sendiri
  const { loading } = useAuth();
  const [refreshSW, setRefreshSW] = useState(false);
  const {
    listAppByKeyword, iframePopup,
  } = useSelector(appState);
  const { user } = useSelector(authState);
  const {
    enterpriseUids = [],
    lastEnterpriseUpdate = null,
  } = useSelector(helperState);


  const { currency: { code: currencyCode } } = useSelector(appState);

  const dispatch = useDispatch();

  const userMeta = useSelector((state) => state.auth.userMeta);

  const utmCampaign = listAppByKeyword ? listAppByKeyword['onboarding_skip_utm']?.value?.list : [];

  const { createProject, openBuyCreationQuota } = useCreations();
  const [, setLoadingTutorial] = useState(false);
  const { logEvent } = useAnalytics();

  const redirectToTutorial = async () => {
    const triggerTutorial = sessionStorage.getItem('triggerTutorial');
    if (triggerTutorial && !sessionStorage.getItem('hasTriggeredTutorial')) {
      sessionStorage.setItem('hasTriggeredTutorial', 'true');
      const { id, title, youtubeId } = JSON.parse(triggerTutorial);
      setLoadingTutorial(true);
      const contentType = (id === 0) ? 'AR on top of Marker' : (id === 1) ? 'AR markerless' : (id === 2) ? 'Interactive' : 'Web Embed';

      await logEvent({
        eventName: 'create_new',
        eventParams: {
          template: null,
          content_type: contentType,
        },
        posthog: true,
        mustBeLoggedIn: true,
      });

      setTimeout(() => {
        createProject({
          withParams: {
            ...(id !== '_blank') && {
              onBoardTitle: title,
              onBoardVideo: youtubeId,
            },
            ...(id === 0) && { onBoardMarker: true },
            from: 'explore',
          },
        })
          .then(() => {
            sessionStorage.removeItem('triggerTutorial');
            sessionStorage.removeItem('hasTriggeredTutorial');
          })
          .catch((_error) => {
            if (_error instanceof ExceededQuotaCreationError) {
              openBuyCreationQuota({ withIntro: false });
            } else {
              alert(`Failed to start tutorial: ${_error.message}`);
              sessionStorage.removeItem('triggerTutorial');
              sessionStorage.removeItem('hasTriggeredTutorial');
            }
            setLoadingTutorial(false);
          })
      }, 500); //dikasih timeout soalnya kadang event ga ke sent karena keburu ganti page}
    }
  };

  const isSkipOnboarding = (delayTime) => (
    (query.get('utm_campaign') && utmCampaign?.includes(query.get('utm_campaign')))
    || query.get('content') === 'checkout'
    || query.get('content') === 'redeem'
    || Math.floor(((Date.now()) - delayTime) / 60 / 60 / 1000) < 24 //kalau masa delay onboardingnya sudah lebih dari 24 jam
  );

  const fetchReward = () => {
    // if (process.env.NODE_ENV !== 'production') return;
    console.log('fetchReward current');
    window.postMessage({
      id: 'list-reward',
      uid: user.uid,
    }, '*');
  };

  const fetchUserPlan = async (uid) => {
    const data = await PaymentServices.getUserPlan(uid);
    dispatch(setUserPlan(data));
    console.log({ showLogin2: data, queryNext });
    if (pathname.includes('explore')) {
      replace({ pathname: '/projects', query: { ...queryNext } });
    }
    dispatch(setLogin(''));
  };

  const initialOnboarding = useCallback(async () => {
    if (user?.uid) {
      await clearCacheByKeys(META_CACHE_KEY_BY_UID);
      const {
        role, sector, interest, age_range, country_code,
      } = await OnBoardingServices().getMyMetaOnBoarding(user?.uid);
      if (!age_range && !country_code) {
        return push('/profile-completion', undefined, { shallow: true });
      }

      if (!role) {
        return push('/onboardings/role', undefined, { shallow: true });
      }

      if (!sector && role) {
        return push(`/onboardings/specialis-sector/${encodeURIComponent(role)}`, undefined, { shallow: true });
      }

      if (interest.length === 0 && role && sector) {
        return push('/onboardings/interests', undefined, { shallow: true });
      }

      fetchReward();
    }
  }, [user]);

  useEffect(() => {
    const delayTime = parseInt(localStorage.getItem('delayOnboarding') || '', 10);

    /*
    * skip onboarding kalau
    * - popup iframe ga muncul
    * - ada param checkout/redeem/utm_campaign
    * - user klik delay dan delaynya masih <= 24 jam
    */
    console.log({ what2: iframePopup.open });

    const triggerIap = sessionStorage.getItem('triggerIap');
    const redirectPage = sessionStorage.getItem('redirectPage');

    if (user?.uid && !isSkipOnboarding(delayTime) && !iframePopup.open && !triggerIap && !redirectPage) {
      if (sessionStorage.getItem('triggerTutorial')) {
        redirectToTutorial();
      } else {
        localStorage.removeItem('delayOnboarding');
        withGrantedPath({
          grantedPaths: ['/', '/templates', '/projects', '/explore'],
          pathname,
          callback: initialOnboarding,
        });
      }
    } else if (user?.uid && !delayTime) {
      localStorage.setItem('delayOnboarding', Date.now());
    } else if (user?.uid && triggerIap) {
      sessionStorage.removeItem('triggerIap');
      window.location.href = `${triggerIap}&uid=${user.uid}`;
    } else if (user?.uid && redirectPage) {
      sessionStorage.removeItem('redirectPage');
      push(redirectPage);
    }
  }, [user?.uid, loading, pathname, isReady, iframePopup.open]);

  const { reset } = useModuleSearch({});
  useEffect(() => {
    const SWLog = new LogWithPrefix('SW');
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          SWLog.debug('Service worker already registered', registration);
          if (registration.waiting) {
            SWLog.debug('Update found and waiting');
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          }
        }
      });
    }

    // TODO : reset search when change page
    const unresetSearchPath = ['/creation/[slug]', '/marketplace/[slug]', '/search'];
    if (!unresetSearchPath.includes(pathname)) {
      reset();
      console.log('reset', pathname);
    }
  }, [pathname]);

  useEffect(() => {
    if (user?.uid) {
      if (!userMeta || (userMeta && userMeta.uid !== user.uid)) {
        dispatch(asyncGetUserMeta(user?.uid));
      }
      fetchUserPlan(user?.uid);
      // if (user && !Versioning.ONE_TIME_CREATION() && pathname !== '/embed_login') {
      //   dispatch(asyncGetListApp({ startWith: 'promo_popup' }));
      //   dispatch(asyncGetListApp({ startWith: 'promo_banner' }));
      // }
    }
  }, [user?.uid]);

  const SWLog = new LogWithPrefix('SW');

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      axios.interceptors.request.use(listenRequestInterceptor);
      instance.interceptors.request.use(listenRequestInterceptor);
      apiService.interceptors.request.use(listenRequestInterceptor);
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        SWLog.debug('New update available');
        if (!refreshSW) {
          // window.location.reload();
          setRefreshSW(true);
        }
      });
    }

    dispatch(asyncGetListApp());
    // if (user && !Versioning.ONE_TIME_CREATION()) {
    //   dispatch(asyncGetListApp({ startWith: 'ads_creation_detail' }));
    // }
    AppServices.getGeoIp();

    // 5 hour
    if (enterpriseUids.length === 0 || !lastEnterpriseUpdate || (new Date().getTime() - lastEnterpriseUpdate) > 1000 * 60 * 60 * 5) {
      console.log('fetch enterprise uids');
      dispatch(asyncGetEnterpriseUids());
    }
  }, []);
};

export default useInitialActions;
