import { getAuth } from 'firebase/auth';
import { NameGenerator } from '../components/miscellaneous/name_generator';
import ExceededQuotaCreationError from '../custom-errors/ExceededQuotaCreationError';
import userAgent from '../utils/userAgent';
import Versioning from '../utils/Versioning';
import apiService from './apiService';

const tryParseJson = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    console.error(e);
  }
  return false;
};

const CreationServices = {
  create: async ({
    name = NameGenerator(),
    feature = false,
    build_mode = 2,
    based_project_id = null,
    source = 3,
    category = [],
  } = {
      name: NameGenerator(),
      feature: false,
      build_mode: 2,
      source: 3,
    }) => {
    try {
      let newSource = source;
      if (userAgent.isMobile.Android() || userAgent.isMobile.IOS() || userAgent.isMobile.Assemblr()) {
        newSource = 4;
      }
      getAuth().currentUser.getIdToken();
      const { data } = await apiService.post('/v2/creations/create/', {
        name, feature, build_mode, source: newSource, category, based_project_id,
      });
      return data;
    } catch (error) {
      try {
        const data = tryParseJson(error.data);
        if (data.error_message === 'Project quota exceeded') {
          throw new ExceededQuotaCreationError();
        } else {
          console.error('Error creating project:', error);
          throw error;
        }
      } catch (_error) {
        console.error('Error creating project:', _error);
        throw _error;
      }
    }
  },
  getCategories: async () => {
    const { data: { category } } = await apiService.get('/v2/category/');
    return category;
  },
  getCreations: async ({ category, page, pageSize }) => {
    const params = {
      page: page || 1,
      row: pageSize || 10,
    };
    if (category) {
      params.parent = category;
    }
    const { data } = await apiService.get('/v2/creations/filter/', { params });
    return data;
  },
  searchCreations: async ({
    featured, is_template, page, users,
  } = {}) => {
    const params = {};
    if (featured) {
      params.featured = 'True';
    }
    if (is_template) {
      params.is_template = 'True';
    }
    if (page) {
      params.page = page;
    }
    if (users) {
      params.users = users;
    }
    const { data: { jumlah, jumlah_data, creations } } = await apiService.get('/v2/creations/search/', { params });
    return { jumlah, jumlah_data, creations };
  },
  getSubCategories: async ({ category }) => {
    const { data: { sub_category: subCategories } } = await apiService.get(`/v2/category/sub/${category}`);
    return subCategories;
  },
  getMyCreations: async ({ page, pageSize }) => {
    const params = {
      page: page || 1,
      row: pageSize || 10,
    };
    const { data: { creations } } = await apiService.get('/v2/creations/my-creations/', { params });
    return { creations };
  },
  getDetailCreation: async (id) => {
    const { data } = await apiService.get(`/v2/creations/${id}`);
    return data;
  },
  quotaAvailable: async (uid = ':uid') => {
    try {
      const { data } = await apiService.get(`${Versioning.TIERED_SUBSCRIPTION() ? '/v2' : ''}/features/total/${uid}${Versioning.ONE_TIME_CREATION() ? '?version=2' : ''
        }`);
      const { marker: max, used_marker: used, quota, quota_used } = data;
      return {
        max,
        used,
        available: max - used,
        storage: {
          max: quota,
          used: quota_used,
          available: quota - quota_used
        },
        data
      };
    } catch {
      return {
        max: 1,
        used: 0,
        available: 1,
        storage: {
          max: 30000000,
          used: 0,
          percentage: 0,
        }
      };
    }
  },
  getStorageQuota: async (uid = ':uid') => {
    // const { data: {
    //   max,
    //   used,
    // } } = await apiService.get(`/features/quota/${uid}/storage/`);
    try {
      const { data: { quota: max, quota_used: used } } = await apiService.get(`/features/total/${uid}`);
      return {
        max,
        used,
        percentage: Math.floor((used / max) * 100),
      };
    } catch {
      return {
        max: 30000000,
        used: 0,
        percentage: 0,
      };
    }
  },
};

export default CreationServices;
