/* eslint-disable react-hooks/exhaustive-deps */
import { differenceInDays } from "date-fns"
import { useEffect } from "react"

const useEditorCount = () => {
  const { now } = Date

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem('openEditorCount')) || []
    const dateDiff = differenceInDays(now(), storage?.lastReset || now())

    if (dateDiff >= 7) {
      localStorage.setItem('openEditorCount', JSON.stringify({ list: [], lastReset: now() }))
    }
  }, [])

  const recordOpenEditor = () => {
    const storage = JSON.parse(localStorage.getItem('openEditorCount')) || []
    const isPromoClosed = localStorage.getItem('promoClosed') !== 'true'
    if (isPromoClosed) {
      const list = storage?.list || []
      const lastReset = storage?.lastReset ? storage.lastReset : now()
      list.push(Date.now())
      localStorage.setItem('openEditorCount', JSON.stringify({ lastReset, list }))
    }
  }

  const getOpenEditorCount = (change) => {
    const storage = JSON.parse(localStorage.getItem('openEditorCount')) || []
    const list = storage?.list || []
    change(list.length)
  }

  const resetRecord = () => {
    localStorage.setItem('promoClosed', true)
    localStorage.removeItem('openEditorCount')
  }

  return {
    recordOpenEditor,
    getOpenEditorCount,
    resetRecord
  }
}

export default useEditorCount 