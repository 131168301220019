import { thunk } from 'redux-thunk';
import { createWrapper } from 'next-redux-wrapper';
import { persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import defaultMiddleware from './middlewares/defaultMiddleware';
import { asblrApi } from './rtk/asblrApi';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';

// initial states here
const initalState = {};

// creating store
export const store = configureStore(
  {
    reducer: rootReducer,
    devTools: ((process.env.NEXT_PUBLIC_WEB_URL).includes('pr-') || (process.env.NODE_ENV !== 'production')),
    preloadedState: initalState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(defaultMiddleware)
      .concat(typeof window !== 'undefined' ? createStateSyncMiddleware({
        broadcastChannelOption: {
          channel: 'state-sync',
        },
        whitelist: ["SET_USERDATA"],
      }) : [])
      .concat(thunk)
      .concat(asblrApi.middleware),
  },
);

// assigning store to next wrapper
const makeStore = () => {
  if (typeof window !== 'undefined') {
    initMessageListener(store);
  }
  return store;
}

export const wrapper = createWrapper(makeStore);

// persistor
export const persistor = persistStore(store);
