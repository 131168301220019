import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userAgent from '../../utils/userAgent';
import AppServices from '../../services/AppService';

const initialState = {
  lastFetch: {},
  exploreSearchPosition: {},
  onBackUrl: null,
  backFrom: null,
  mainLoader: false,
  unityVersion: null,
  progressLoader: {
    show: false,
    message: 'Syncing',
  },
  activeItems: {},
  enterpriseUids: [],
  lastEnterpriseUpdate: null,
};

const mainLoaderMinVersion = '4.2.3';

const isMainLoaderMinVersion = (version) => {
  const versionArray = version.split('.');
  const minVersionArray = mainLoaderMinVersion.split('.');
  const calcVersion = (versionArray[0] * 100) + (versionArray[1] * 10) + (versionArray[2] * 1);
  const calcMinVersion = (minVersionArray[0] * 100) + (minVersionArray[1] * 10) + (minVersionArray[2] * 1);
  return calcVersion >= calcMinVersion;
};

export const isRecoverLostProjectMinVersion = (version) => {
  const minVersion = '4.2.13';
  const versionArray = version.split('.');
  const minVersionArray = minVersion.split('.');
  const calcVersion = (versionArray[0] * 100) + (versionArray[1] * 10) + (versionArray[2] * 1);
  const calcMinVersion = (minVersionArray[0] * 100) + (minVersionArray[1] * 10) + (minVersionArray[2] * 1);
  return calcVersion >= calcMinVersion;
};

export const isSameOrNewerVersion = (version, compareVersion) => {
  const versionArray = version.split('.');
  const compareVersionArray = compareVersion.split('.');
  const calcVersion = (versionArray[0] * 100) + (versionArray[1] * 10) + (versionArray[2] * 1);
  const calcCompareVersion = (compareVersionArray[0] * 100) + (compareVersionArray[1] * 10) + (compareVersionArray[2] * 1);
  return calcVersion >= calcCompareVersion;
};

export const asyncGetUnityVersion = createAsyncThunk(
  'helper/getUnityVersion',
  async () => {
    const version = document.getElementById('unity-version')?.value;
    if (userAgent.isMobile.Assemblr() && (!version || version === '')) {
      window.location = 'assemblr://requestunityversion';
      setTimeout(() => version, 200);
    }
    return version;
  },
);

export const asyncGetEnterpriseUids = createAsyncThunk(
  'app/getEnterpriseUids',
  async () => {
    const enterpriseUids = await AppServices.getEnterpriseUids();
    return enterpriseUids;
  },
);

const helperSlice = createSlice({
  name: 'helper',
  initialState,
  reducers: {
    setLastFetch: (state, { payload }) => {
      state.lastFetch[payload] = Date.now().toString();
    },
    setExploreSearchPosition: (state, { payload }) => {
      state.exploreSearchPosition = payload;
    },
    setOnBackUrl: (state, { payload: { onBackUrl, backFrom } }) => {
      state.onBackUrl = onBackUrl;
      state.backFrom = backFrom;
    },
    clearOnBackUrl: (state) => {
      state.onBackUrl = null;
      state.backFrom = null;
    },
    setMainLoader: (state, { payload }) => {
      if (isMainLoaderMinVersion(state.unityVersion || '0.0.0')) {
        state.mainLoader = payload;
      }
    },
    setUnityVersion: (state, { payload }) => {
      if (payload) {
        state.unityVersion = payload;
      }
    },
    setProgressLoader: (state, { payload }) => {
      state.progressLoader = {
        ...state.progressLoader,
        ...payload,
      };
    },
    setActiveItems: (state, { payload }) => {
      state.activeItems = {
        ...state.activeItems,
        ...payload,
      };
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(asyncGetUnityVersion.fulfilled, (state, { payload }) => {
      if (payload) {
        state.unityVersion = payload;
      }
    });
    addCase(asyncGetEnterpriseUids.fulfilled, (state, { payload }) => {
      state.enterpriseUids = payload;
      state.lastEnterpriseUpdate = new Date().getTime();
    });
    addCase(asyncGetEnterpriseUids.rejected, (state) => {
      state.lastEnterpriseUpdate = null;
    });
    addCase(asyncGetEnterpriseUids.pending, (state) => {
      state.lastEnterpriseUpdate = null;
    });
  },
});

export const {
  setLastFetch,
  setExploreSearchPosition,
  setOnBackUrl,
  clearOnBackUrl,
  setMainLoader,
  setUnityVersion,
  setProgressLoader,
  setActiveItems,
} = helperSlice.actions;

export const helperState = (state) => state.helper;

export const activeItemState = (state) => (key) => state.helper.activeItems[key];

const helperReducer = helperSlice.reducer;

export default helperReducer;
