const Log = {
    // isDev: process.env.NODE_ENV === 'development',
    isDev: true,
    styles: [
        `border-radius: 0.5em`,
        `color: white`,
        `font-weight: bold`,
        `padding: 2px 0.5em`,
    ],
    debug: (...args) => {
        if (!Log.isDev) return;
        const styles = [
            ...Log.styles,
            `background-color: #1e88e5`,
        ];
        const prefix = [`%cDEBUG`, styles.join(';')];
        console.debug(...prefix, ...args);
    },
    info: (...args) => {
        if (!Log.isDev) return;
        const styles = [
            ...Log.styles,
            `background-color: #00acc1`,
        ];
        const prefix = [`%cINFO`, styles.join(';')];
        console.info(...prefix, ...args);
    },
    warn: (...args) => {
        if (!Log.isDev) return;
        const styles = [
            ...Log.styles,
            `background-color: #ffa000`,
        ];
        const prefix = [`%cWARN`, styles.join(';')];
        console.warn(...prefix, ...args);
    },
    error: (...args) => {
        if (!Log.isDev) return;
        const styles = [
            ...Log.styles,
            `background-color: #d32f2f`,
        ];
        const prefix = [`%cERROR`, styles.join(';')];
        console.error(...prefix, ...args);
    },
    withPrefix: (prefix, color='#F00', ...args) => {
        if (!Log.isDev) return;
        const styles = [
            ...Log.styles,
            `background-color: ${color}`,
        ];
        const prefixStyles = [`%c${prefix}`, styles.join(';')];
        console.log(...prefixStyles, ...args);
    }
}

export default Log;

class LogWithPrefix {
    constructor(prefix, color='#F00') {
        this.prefix = prefix;
        this.color = color;
    }
    debug(...args) {
        Log.withPrefix(this.prefix, this.color, ...args);
    }
    info(...args) {
        Log.withPrefix(this.prefix, this.color, ...args);
    }
    warn(...args) {
        Log.withPrefix(this.prefix, this.color, ...args);
    }
    error(...args) {
        Log.withPrefix(this.prefix, this.color, ...args);
    }
}

export { LogWithPrefix };