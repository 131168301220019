import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CreationServices from '../../services/CreationServices';
import Log from '../../utils/Log';
import mapByKeys from '../../utils/mapByKeys';
import { setLastFetch } from './helperReducer';

const initialState = {
  categories: [],
  loadingCategories: false,
  errorCategories: null,
  loadingCreations: {},
  errorCreations: {},
  loadingSubCategories: {},
  errorSubCategories: {},
  featuredCreations: [],
  loadingFeaturedCreations: false,
  errorFeaturedCreations: null,
  myCreations: null,
};

export const creationFetchKeys = {
  asyncGetCategories: 'asyncGetCategories',
  asyncGetCreations: (category) => `asyncGetCreations_${category}`,
  asyncGetFeaturedCreations: 'asyncGetFeaturedCreations',
  asyncGetSubCategories: (category) => `asyncGetSubCategories_${category}`,
};

export const asyncGetCategories = createAsyncThunk(
  'categories/getCategories',
  async (_, { dispatch }) => {
    dispatch(setLastFetch(creationFetchKeys.asyncGetCategories));
    const categories = await CreationServices.getCategories();
    return { categories };
  },
);

export const asyncGetFeaturedCreations = createAsyncThunk(
  'categories/getFeaturedCreations',
  async () => {
    const { creations } = await CreationServices.searchCreations({ featured: true, is_template: true });
    return { creations };
  },
);

export const asyncGetCreations = createAsyncThunk(
  'categories/getCreations',
  async ({ category, page, pageSize }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { creations } = await CreationServices.getCreations({ category, page, pageSize });
      return fulfillWithValue({ creations, category });
    } catch (error) {
      return rejectWithValue({ error: error.message, category });
    }
  },
);

export const asyncGetSubCategories = createAsyncThunk(
  'categories/getSubCategories',
  async ({ category }, { rejectWithValue, fulfillWithValue }) => {
    Log.error('asyncGetSubCategories', category);
    try {
      const subCategories = await CreationServices.getSubCategories({ category });
      return fulfillWithValue({ subCategories, category });
    } catch (error) {
      return rejectWithValue({ error: error.message, category });
    }
  },
);

const creationKeys = ['Name', 'Slug', 'PublishedThumbnail', 'contains_premium_item', 'Pblr', 'HostId'];
const categoryKeys = ['name', 'slug', 'thumbnail', 'subCategories', 'creations', 'description', 'background_images', 'background_color'];

export const creationSlice = createSlice({
  name: 'creation',
  initialState,
  reducers: {
    resetCategories: (state) => {
      state.categories = [];
    },
    setMyCreations: (state, { payload }) => {
      if (state.myCreations === null) {
        state.myCreations = payload;
      } else {
        const mergedCreations = [...state.myCreations, ...payload].reduce((acc, creation) => {
          let existingCreation = acc.find((crea) => crea.Slug === creation.Slug);
          if (existingCreation) {
            existingCreation = creation;
          } else {
            acc.push(creation);
          }
          return acc;
        }, []);
        state.myCreations = mergedCreations.sort((a, b) => b.Id - a.Id);
      }
    },
    resetMyCreations: (state) => {
      state.myCreations = null;
    },
    deleteMyCreation: (state, { payload }) => {
      state.myCreations = state.myCreations.filter((creation) => [creation.Pblr, creation.Id].indexOf(payload) === -1);
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(asyncGetCategories.pending, (state) => {
      state.loadingCategories = true;
    });
    addCase(asyncGetCategories.fulfilled, (state, { payload }) => {
      if (state.categories.length === 0) {
        state.categories = payload.categories.map((category) => mapByKeys(category, categoryKeys));
      } else {
        const mergedCategories = [...state.categories, ...payload.categories].reduce((acc, category) => {
          const existingCategory = acc.find((cat) => cat.slug === category.slug);
          if (existingCategory) {
            existingCategory.creations = category.creations;
          } else {
            acc.push(mapByKeys(category, categoryKeys));
          }
          return acc;
        }, []);
        state.categories = mergedCategories;
      }
      state.loadingCategories = false;
    });
    addCase(asyncGetCategories.rejected, (state, { error }) => {
      state.loadingCategories = false;
      state.error = error.message;
    });

    addCase(asyncGetCreations.pending, (state, { meta: { arg: { category } } }) => {
      state.loadingCreations = {
        ...state.loadingCreations,
        [category]: true,
      };
    });
    addCase(asyncGetCreations.fulfilled, (state, { payload: { creations, category } }) => {
      const categoryIndex = state.categories.findIndex((cat) => cat.slug === category);
      if (categoryIndex !== -1) {
        state.categories[categoryIndex].creations = creations
          .map((creation) => mapByKeys(creation, creationKeys));
      }
      state.loadingCreations = {
        ...state.loadingCreations,
        [category]: false,
      };
    });
    addCase(asyncGetCreations.rejected, (state, { payload: { error, category } }) => {
      state.loadingCreations = {
        ...state.loadingCreations,
        [category]: false,
      };
      state.errorCreations = {
        ...state.errorCreations,
        [category]: error,
      };
    });

    addCase(asyncGetFeaturedCreations.pending, (state) => {
      state.loadingFeaturedCreations = true;
    });
    addCase(asyncGetFeaturedCreations.fulfilled, (state, { payload: { creations } }) => {
      state.featuredCreations = creations?.map((creation) => mapByKeys(creation, creationKeys));
      state.loadingFeaturedCreations = false;
    });
    addCase(asyncGetFeaturedCreations.rejected, (state, { error }) => {
      state.loadingFeaturedCreations = false;
      state.errorFeaturedCreations = error.message;
    });

    addCase(asyncGetSubCategories.pending, (state, { meta: { arg: { category } } }) => {
      state.loadingSubCategories = {
        ...state.loadingSubCategories,
        [category]: true,
      };
    });
    addCase(asyncGetSubCategories.fulfilled, (state, { payload: { subCategories, category } }) => {
      const categoryIndex = state.categories.findIndex((cat) => cat.slug === category);
      if (categoryIndex !== -1) {
        state.categories[categoryIndex].subCategories = subCategories;
      }
      state.loadingSubCategories = {
        ...state.loadingSubCategories,
        [category]: false,
      };
      Log.error('asyncGetSubCategoriesFulfilled', state.categories);
    });
    addCase(asyncGetSubCategories.rejected, (state, { payload: { error, category } }) => {
      state.loadingSubCategories = {
        ...state.loadingSubCategories,
        [category]: false,
      };
      state.errorSubCategories = {
        ...state.errorSubCategories,
        [category]: error.message,
      };
      Log.error('asyncGetSubCategoriesRejected', state.categories);
    });
  },
});

export const {
  resetCategories,
  setMyCreations,
  resetMyCreations,
  deleteMyCreation,
} = creationSlice.actions;
export const creationState = (state) => state.creation;
export const subCategoriesState = (state, category) => (category ? state.creation.categories.find((cat) => cat.slug === category)?.subCategories : null);
export const categoryBySlug = (state, slug) => state.creation.categories.find((cat) => cat.slug === slug);

const creationReducer = creationSlice.reducer;

export default creationReducer;
