import { useDispatch, useSelector } from "react-redux"
import { notificationState, setActiveNotification, setStatus } from "../store/reducers/notificationReducer"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"

const useNotificationWorker = () => {
    const { status, generalSequence, delayBetweenNotification } = useSelector(notificationState)
    const dispatch = useDispatch();

    const [queueInterval, setQueueInterval] = useState(null)

    useEffect(() => {
        if (status === 'inactivating') {
            setQueueInterval(setInterval(() => {
                dispatch(setStatus('idle'));
            }, delayBetweenNotification))
        } else {
            if (queueInterval) {
                clearInterval(queueInterval)
            }
        }
    }, [status])

    const { pathname } = useRouter()

    useEffect(() => {
        const hasReadWithCoolDownNotification = generalSequence?.filter((notification) => notification.hasSeen && notification?.reactiveTime);
        if (hasReadWithCoolDownNotification?.length > 0) {
            const currentDate = new Date()
            hasReadWithCoolDownNotification.forEach((notification) => {
                const { reactiveTime } = notification
                const cooldownDate = new Date(reactiveTime)
                if (currentDate > cooldownDate) {
                    dispatch(setActiveNotification(notification.key))
                }
            })
        }
        // Listen to pathname change
    }, [generalSequence, pathname])

    return {
        queueInterval,
    }
}
export default useNotificationWorker
