import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  promoBanners: [],
  popUpBanners: [],
  tallySurveys: [],
};

const posthogSlice = createSlice({
  name: 'posthog',
  initialState,
  reducers: {
    setPromoBanners: (state, action) => {
      const promoBannersWithoutClosed = state.promoBanners.map((promoBanner) => {
        const newPromoBanner = promoBanner;
        delete newPromoBanner.closed;
        return newPromoBanner;
      });
      if (JSON.stringify(promoBannersWithoutClosed) === JSON.stringify(action.payload)) {
        return;
      }
      if (!action.payload) {
        state.promoBanners = [];
        return;
      }
      const closedIds = state.promoBanners.filter((promoBanner) => promoBanner.closed).map((promoBanner) => promoBanner.id);
      state.promoBanners = action.payload.map((promoBanner) => {
        if (closedIds.includes(promoBanner.id)) {
          promoBanner.closed = true;
        } else {
          promoBanner.closed = false;
        }
        return promoBanner;
      });
    },
    closePromoBanner: (state, action) => {
      const promoBanner = state.promoBanners.find((_promoBanner) => _promoBanner.id === action.payload);
      if (promoBanner) {
        promoBanner.closed = true;
      }
    },
    setPopUpBanners: (state, action) => {
      const popUpBannersWithoutClosed = state.popUpBanners.map((popUpBanner) => {
        const newPopUpBanner = popUpBanner;
        delete newPopUpBanner.closed;
        return newPopUpBanner;
      });
      if (JSON.stringify(popUpBannersWithoutClosed) === JSON.stringify(action.payload)) {
        return;
      }
      if (!action.payload) {
        state.popUpBanners = [];
        return;
      }
      const closedIds = state.popUpBanners.filter((popUpBanner) => popUpBanner.closed).map((popUpBanner) => popUpBanner.id);
      state.popUpBanners = action.payload.map((popUpBanner) => {
        if (closedIds.includes(popUpBanner.id)) {
          popUpBanner.closed = true;
        } else {
          popUpBanner.closed = false;
        }
        return popUpBanner;
      });
    },
    closePopUpBanner: (state, action) => {
      const popUpBanner = state.popUpBanners.find((_popUpBanner) => _popUpBanner.id === action.payload);
      if (popUpBanner) {
        popUpBanner.closed = true;
      }
    },
    setTallySurveys: (state, action) => {
      const tallySurveysWithoutClosed = state.tallySurveys.map((tallySurvey) => {
        const newTallySurvey = tallySurvey;
        delete newTallySurvey.closed;
        return newTallySurvey;
      });
      if (JSON.stringify(tallySurveysWithoutClosed) === JSON.stringify(action.payload)) {
        return;
      }
      if (!action.payload) {
        state.tallySurveys = [];
        return;
      }
      const closedIds = state.tallySurveys.filter((tallySurvey) => tallySurvey.closed).map((tallySurvey) => tallySurvey.id);
      state.tallySurveys = action.payload.map((tallySurvey) => {
        if (closedIds.includes(tallySurvey.id)) {
          tallySurvey.closed = true;
        } else {
          tallySurvey.closed = false;
        }
        return tallySurvey;
      });
    },
    closeTallySurvey: (state, action) => {
      const tallySurvey = state.tallySurveys.find((_tallySurvey) => _tallySurvey.id === action.payload);
      if (tallySurvey) {
        tallySurvey.closed = true;
      }
    },
  },
});

export const {
  setPromoBanners,
  closePromoBanner,
  setPopUpBanners,
  closePopUpBanner,
  setTallySurveys,
  closeTallySurvey,
} = posthogSlice.actions;

export const randomPromoBanner = (state) => {
  const promoBanners = state.posthog.promoBanners.filter((promoBanner) => !promoBanner.closed);
  if (promoBanners.length === 0) {
    return null;
  }
  const randomIndex = Math.floor(Math.random() * promoBanners.length);
  return promoBanners[randomIndex];
};

export const posthogState = (state) => state.posthog;

const posthogReducer = posthogSlice.reducer;

export default posthogReducer;
