const locale = {
  IDR: 'id-ID',
  USD: 'en-US',
};

const currFormat = (val, code) => {
  const curr = new Intl.NumberFormat(locale[code || 'IDR'], {
    style: 'currency',
    currency: code || 'IDR',
  });
  return curr.format(val);
};

export default currFormat;
