/* eslint-disable default-param-last */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PaymentServices from '../../services/PaymentServices';

export const notificationExludePaths = ['/redirect', '/onboardings', '/profile-completion', '/page', '/embed_login'];

export const unpaidType = {
  COIN: 'Coin',
  MARKER: 'Miscellaneous',
  PLAN: 'Personal',
  LIMITED_ADDONS: 'Limited Add-ons'
};

export const unpaidTypeArray = Object.keys(unpaidType).map((key) => unpaidType[key]);

const initialState = {
  unpaidInvoice: null,
  unpaidInvoiceType: null,
  generalSequence: [],
  status: 'idle',
  currentGeneralNotification: null,
  delayBetweenNotification: 5 * 1000,
  delayUnpaidInvoiceNotification: 60 * 60 * 24 * 1000,
  verifyEmailPopup: false,
  verifyEmailSent: false,
  fourContentType: null,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setUnpaidInvoice: (state, action) => {
      state.unpaidInvoice = action.payload;
    },
    setUnpaidInvoiceType: (state, action) => {
      if (!action.payload) return;
      state.unpaidInvoiceType = action.payload;
    },
    setVerifyEmailPopup: (state, { payload }) => {
      state.verifyEmailPopup = payload;
    },
    setVerifyEmailSent: (state) => {
      state.verifyEmailSent = true;
    },
    queueNotification: (state, action) => {
      const hasNotification = state.generalSequence.find((notification) => notification.key === action.payload.key);
      if (!hasNotification) {
        state.generalSequence.push({
          ...action.payload,
          hasSeen: false,
          priority: action.payload.priority || 0,
        });
      } else {
        state.generalSequence = state.generalSequence.map((notification) => {
          if (notification.key === action.payload.key) {
            return {
              ...notification,
              hasSeen: action.payload.hasSeen === undefined ? notification.hasSeen : action.payload.hasSeen,
            };
          }
          return notification;
        });
      }
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    resetNotification(state) {
      Object.assign(state, {
        ...initialState,
        unpaidInvoiceType: state.unpaidInvoiceType,
      });
    },
    setHasSeenNotification(state, action) {
      state.generalSequence = state.generalSequence.map((notification) => {
        const { withCooldown = false, key } = action.payload;
        let reactiveTime = null;
        const isUnpaidInvoice = notification.type === 'unpaid_invoice';
        if (isUnpaidInvoice) {
          if (notification.payload?.type === 'popup') {
            reactiveTime = Date.now();
          } else {
            reactiveTime = Date.now() + state.delayUnpaidInvoiceNotification;
          }
        } else {
          reactiveTime = withCooldown && notification.payload?.cooldown_time ? Date.now() + notification.payload.cooldown_time * 1000 : null;
        }

        if (notification.key === key) {
          return {
            ...notification,
            hasSeen: true,
            reactiveTime,
            payload: {
              ...notification.payload,
              // type: notification.payload?.type === 'popup' ? 'banner' : 'popup',
              type: 'banner',
            },
          };
        }
        return notification;
      });
    },
    setCurrentGeneralNotification(state, action) {
      state.currentGeneralNotification = action.payload;
    },
    setActiveNotification(state, action) {
      state.generalSequence = state.generalSequence.map((notification) => {
        if (notification.key === action.payload) {
          return {
            ...notification,
            hasSeen: false,
          };
        }
        return notification;
      });
    },
    setDelayBetweenNotification(state, action) {
      state.delayBetweenNotification = action.payload * 1000;
    },
    setDelayUnpaidInvoiceNotification(state, action) {
      state.delayUnpaidInvoiceNotification = action.payload;
    },
    evaluateNotificationByFlags: (state, action) => {
      const newNotifications = state
        .generalSequence
        .filter((notification) => (!notification.payload?.featureFlag ? true : action.payload.includes(notification.payload?.featureFlag)));
      state.generalSequence = newNotifications;
    },
    setFourContentType: (state, action) => {
      state.fourContentType = action.payload;
    },
  },
});

export const setInactiveNotification = createAsyncThunk(
  'notification/setInactiveNotification',
  async ({ withCooldown = false } = { withCooldown: false }, { dispatch, getState }) => {
    const { notification: { generalSequence } } = getState();
    const currentActiveNotification = generalSequence.map((a) => a)?.sort((a, b) => b.priority - a.priority).filter((notification) => !notification.hasSeen)[0];
    dispatch(notificationSlice.actions.setHasSeenNotification({ key: currentActiveNotification.key, withCooldown }));
    dispatch(notificationSlice.actions.setStatus('inactivating'));
    setTimeout(() => {
      dispatch(notificationSlice.actions.setStatus('idle'));
    }, getState().notification.delayBetweenNotification);
  },
);

export const {
  setUnpaidInvoice,
  setUnpaidInvoiceType,
  queueNotification,
  setStatus,
  resetNotification,
  setActiveNotification,
  setDelayBetweenNotification,
  setDelayUnpaidInvoiceNotification,
  evaluateNotificationByFlags,
  setVerifyEmailPopup,
  setVerifyEmailSent,
  setFourContentType,
} = notificationSlice.actions;

const getPaymentType = ({ type, name }) => {
  if (type === unpaidType.PLAN) {
    return unpaidType.PLAN;
  }
  if (name?.includes('BLR')) {
    return unpaidType.COIN;
  }

  if (name?.includes('Marker')) {
    return unpaidType.MARKER;
  }

  if (name?.includes('Extra Project')) {
    return unpaidType.LIMITED_ADDONS;
  }

  return 'HIDE AJA DULU';
};

export const asyncGetInitialUnpaidInvoiceNotifications = createAsyncThunk(
  'notification/asyncGetInitialUnpaidInvoiceNotification',
  async (payload, { getState, dispatch }) => {
    try {
      const unpaidInvoices = await PaymentServices.getLastUnpaidOrder();
      if (unpaidInvoices.length === 0) {
        return;
      }

      const availableUnpaidInvoices = unpaidInvoices.filter((invoice) => unpaidTypeArray.includes(invoice.pricing_plan?.plans_type));
      availableUnpaidInvoices.map(async (unpaidInvoice) => {
        let isCanceled = false;
        if (unpaidInvoice?.payments_method === 'midtrans') {
          const midtranStatus = await PaymentServices.getPaymentStatus({ invoiceId: unpaidInvoice?.order_id });
          isCanceled = midtranStatus?.status === 'canceled';
        }
        if (!isCanceled) {
          const plansType = getPaymentType({ type: unpaidInvoice?.pricing_plan?.plans_type, name: unpaidInvoice?.pricing_plan?.name });
          dispatch(queueNotification({
            type: 'unpaid_invoice',
            key: unpaidInvoice.order_id,
            payload: {
              ...unpaidInvoice,
              plans_type: plansType,
              pricing_plan: {
                ...unpaidInvoice?.pricing_plan,
                plans_type: plansType,
              },
              type: unpaidInvoice?.pricing_plan?.plans_type === unpaidType.COIN || unpaidInvoice?.pricing_plan?.plans_type === unpaidType.MARKER ? 'banner' : 'popup',
              cooldown_time: getState().notification.delayUnpaidInvoiceNotification,
            },
            uid: getState().auth.user?.uid,
          }));
        }
      });
    } catch (error) {
      console.log(error);
    }
  },
);

export const asyncGetInitialRenewalInvoiceNotifications = createAsyncThunk(
  'notification/asyncGetInitialRenewalInvoiceNotifications',
  async (payload, { getState, dispatch }) => {
    try {
      const {
        active_plan: {
          payment_method: paymentMethod, expire_date: expireDate, ...rest
        },
      } = await PaymentServices.getActiveFeatures();
      const gracePeriod = getState().app?.listAppByKeyword?.grace_period_monthly?.value ?? 7;
      const isNegative = gracePeriod < 0;

      const expireDateWithGracePeriod = {
        start: new Date(isNegative ? new Date(expireDate).setDate(new Date(expireDate).getDate() + gracePeriod) : new Date(expireDate)),
        end: new Date(isNegative ? new Date(expireDate) : new Date(expireDate).setDate(new Date(expireDate).getDate() + gracePeriod)),
      };

      const showRenewalNotification = new Date().getTime() > expireDateWithGracePeriod.start.getTime() && new Date().getTime() < expireDateWithGracePeriod.end.getTime();

      if ((paymentMethod === 'midtrans' || paymentMethod === 'onetime') && showRenewalNotification) {
        dispatch(queueNotification({
          type: 'renewal_invoice',
          key: 'renewal_invoice',
          payload: {
            ...rest,
            type: 'banner',
            payment_method: paymentMethod,
            expire_date: expireDate,
            cooldown_time: getState().notification.delayUnpaidInvoiceNotification,
          },
          uid: getState().auth.user.uid,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  },
);

export const notificationState = (state) => state.notification;

export const currentActiveNotification = (state) => {
  if (typeof window !== 'undefined' && window.sessionStorage.getItem('triggerTutorial')) {
    return null;
  }

  const { generalSequence, status } = state.notification;
  if (generalSequence?.filter((notification) => !notification.hasSeen).length === 0) {
    return null;
  }

  if (status === 'inactivating') {
    return null;
  }

  return generalSequence.map((a) => a)?.sort((a, b) => b.priority - a.priority).filter((notification) => !notification.hasSeen)[0];
};

const notificationReducer = notificationSlice.reducer;

export default notificationReducer;
