/* eslint-disable import/no-cycle */
import mixpanel, { get_distinct_id } from 'mixpanel-browser';
import { gtag, install } from 'ga-gtag';
import { useCallback, useEffect } from 'react';
import { posthog } from 'posthog-js';
import { useAnalyticState } from 'assemblr-ui';
import useAuth from './useAuth';
import { GA_MEASUREMENT_ID, MIX_PANEL_TOKEN } from '../config/analytics';
import userAgent from '../utils/userAgent';

mixpanel.init(MIX_PANEL_TOKEN, {
  track_pageview: true,
  cross_site_cookie: true,
  cross_subdomain_cookie: true,
});

export const currentDistinctId = () => mixpanel.get_distinct_id();

const useAnalytics = (listenUser = false) => {
  const { user } = useAuth();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      install(GA_MEASUREMENT_ID);
      if (user) {
        gtag('config', GA_MEASUREMENT_ID, {
          user_id: user.uid,
        });
      }
    }

    if (user && listenUser) {
      const currentAlias = mixpanel.get_property('__alias');

      const deviceId = mixpanel.get_property('$device_id');
      if (!currentDistinctId()?.includes?.('$device') && user.uid !== currentDistinctId()) {
        mixpanel.reset();
      }

      mixpanel.identify(user.uid);
      mixpanel.people.set({
        $email: user.email,
        $name: user.displayName,
        $avatar: user.photoURL,
      });

      if (currentAlias !== user?.uid) {
        mixpanel.alias(user.uid, deviceId);
      }
    }

    if (userAgent.isMobile.Assemblr()) {
      if (window.__lastDistinctId !== currentDistinctId()) {
        window.__lastDistinctId = currentDistinctId();
        window.location = `assemblr://setmixpaneldistinctid?distinctid=${currentDistinctId()}`;
      }
    }
  }, [user]);

  const logWithGA = ({ eventName, eventParams = {} }) => {
    try {
      gtag('event', eventName, {
        ...eventParams,
      });
    } catch (error) {
      console.log('Error logging event', error);
    }
  };

  const logWithMixpanel = useCallback(({ eventName, eventParams = {}, uid }) => {
    try {
      const params = {
        ...eventParams,
      };

      if (uid) {
        params.distinct_id = uid;
      }
      mixpanel.track(eventName, params);
    } catch (error) {
      console.log('Error logging event', error);
    }
  }, []);

  const logWithPosthog = useCallback(({ eventName, eventParams = {}, uid }) => {
    try {
      if (posthog) {
        const params = {
          ...eventParams,
        };
        if (uid) {
          params.distinct_id = uid;
        }
        posthog.capture(eventName, params);
      }
    } catch (error) {
      console.log('Error logging event', error);
    }
  }, []);

  const logEvent = useCallback(async ({
    eventName, eventParams = {}, mustBeLoggedIn = true, posthog: _posthog = false,
  }) => {
    try {
      const localUid = localStorage.getItem('UUID');

      if (mustBeLoggedIn && !user && !localUid) {
        throw new Error('User must be logged in to log event');
      } else {
        if (_posthog) {
          logWithPosthog({ eventName, eventParams, uid: localUid });
        }
        logWithGA({ eventName, eventParams });
        logWithMixpanel({ eventName, eventParams, uid: localUid });
      }
      return true;
    } catch (error) {
      console.log('Error logging event', error);
      return false;
    }
  }, []);

  const customLogSearchListener = (event) => {
    if (event.detail) {
      logEvent({
        eventName: 'search',
        eventParams: event.detail,
        mustBeLoggedIn: false,
      });
    }
  };

  useEffect(() => {
    if (listenUser) {
      window.addEventListener('logSearch', customLogSearchListener);
    }

    return () => {
      if (listenUser) {
        window.removeEventListener('logSearch', customLogSearchListener);
      }
    };
  }, []);

  // FROM ASSEMBLR-UI
  const { log } = useAnalyticState();

  useEffect(() => {
    if (log) {
      if (JSON.stringify(log) === window.lastLog) {
        return;
      }
      window.lastLog = JSON.stringify(log);
      logEvent({
        eventName: log.name,
        eventParams: log.params,
        mustBeLoggedIn: false,
      });
    }
  }, [log]);

  return {
    logEvent,
    mixpanel,
  };
};

export default useAnalytics;
