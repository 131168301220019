// User Data
export const GET_USERDATA = "GET_USERDATA";
export const SET_USERDATA = "SET_USERDATA";
export const GET_LOGIN = "GET_LOGIN";
export const ERROR = "ERROR";
export const GET_DATA = "GET_DATA";
export const SET_DATA = "SET_DATA";

// UI
export const SET_LAYOUT_TRANSITION_DIRECTION = "SET_LAYOUT_TRANSITION_DIRECTION";
export const GET_LAYOUT_TRANSITION_DIRECTION = "GET_LAYOUT_TRANSITION_DIRECTION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const SET_REMOTE_CONFIGS = "SET_REMOTE_CONFIGS";

// CREATION
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GET_CREATIONS_BY_CATEGORY = "GET_CREATIONS_BY_CATEGORY";
export const SET_CREATIONS_BY_CATEGORY = "SET_CREATIONS_BY_CATEGORY";