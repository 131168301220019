import { LogWithPrefix } from "../../utils/Log";

const requests = {};
const Log = new LogWithPrefix('RequestInterceptor');
const listenRequestInterceptor = (config) => {
    if (config.method === 'get') {
        if (typeof window === 'undefined') return config;
        const currentPage = window.location.pathname;
        const url = config.url;
        const key = `${currentPage}::${url}`;


        if (requests[key]) {
            const diferenceTime = new Date().getTime() - requests[key].lastTime;
            Log.info(`${key} - ${requests[key].count + 1} times in ${diferenceTime}ms`);

            requests[key] = {
                ...requests[key],
                count: requests[key].count + 1,
                lastTime: new Date().getTime(),
            }
        } else {
            Log.info(`fetching ${key} ${url}`);
            requests[key] = {
                count: 1,
                lastTime: new Date().getTime(),
            }
        }
    }
    return config;
}

export default listenRequestInterceptor;