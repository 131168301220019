/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-danger */
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { AiOutlineDown } from '@react-icons/all-files/ai/AiOutlineDown';
import { AiOutlineUp } from '@react-icons/all-files/ai/AiOutlineUp';
import { useRouter } from 'next/router';
import { Modal as AssemblrModal, ThemeWrapper } from 'assemblr-ui';
import useAnalytics from '../../../hooks/useAnalytics';
import { closePopUpBanner, posthogState } from '../../../store/reducers/posthogReducer';
import {
  currentActiveNotification, notificationExludePaths, queueNotification, setInactiveNotification, unpaidTypeArray,
} from '../../../store/reducers/notificationReducer';
import styles from './styles.module.scss';
import classnames from '../../../utils/classnames';
import { paymentUrl } from '../../../config/getdomain';
import Helpers from '../../../utils/Helpers';
import useAuth from '../../../hooks/useAuth';
// import SurveyCreator from '../survey_creator';
// import config from '../survey_creator/config';
import currFormat from '../../../utils/currencyFormatter';
import { appState, setIframePopup } from '../../../store/reducers/appReducer';
import { authState } from '../../../store/reducers/authReducer';
import Segmentations from '../../../utils/Segmentations';
import Versioning from '../../../utils/Versioning';

function PopupBannerWithPosthog() {
  const dispatch = useDispatch();

  const { popUpBanners } = useSelector(posthogState);
  const { listAppByKeyword, currency } = useSelector(appState);
  const { userMeta, userPlan, user, userData } = useSelector(authState);
  const { iframePopup: { open: iframePopupOpen } } = useSelector(appState);

  useEffect(() => {
    popUpBanners?.forEach((popUpBanner) => {
      dispatch(queueNotification({
        type: 'popup_banner',
        key: `popup_banner_${popUpBanner?.id}`,
        payload: popUpBanner,
      }));
    });

    if (user?.uid) {
      Object.keys(listAppByKeyword || {})
        .sort((a, b) => listAppByKeyword[a]?.value?.conditions?.segment_membership ? -1 : 1)
        .forEach((key) => {
          if (Segmentations.inCriteriaConfigCheck({
            key,
            targetKey: 'promo_popup',
            configs: listAppByKeyword,
            userMeta,
            userPlan,
            currency,
            user,
            userData
          }) && !Versioning.ONE_TIME_CREATION()) {
            dispatch(queueNotification({
              type: 'popup_banner',
              key: `popup_banner_${listAppByKeyword[key]?.value?.id}`,
              payload: listAppByKeyword[key]?.value,
            }));
          }
        });
    }
  }, [dispatch, popUpBanners, listAppByKeyword, user, userMeta, userPlan, currency]);

  const _currentActiveNotification = useSelector(currentActiveNotification);

  const popUpBanner = useMemo(() => {
    if (_currentActiveNotification?.type === 'popup_banner') {
      return _currentActiveNotification.payload;
    }
    return null;
  }, [_currentActiveNotification]);

  const onCancel = (withCooldown = true) => {
    dispatch(closePopUpBanner(popUpBanner?.id));
    dispatch(setInactiveNotification({ withCooldown }));
  };

  const { logEvent } = useAnalytics();

  const handleClick = async (action) => {
    const logParams = Helpers.promoEventLog({
      promoType: 'popup',
      promoAction: action?.name || (action?.action_type === 'CTA' ? 'cta_clicked' : 'dismissed'),
      featureFlag: popUpBanner?.featureFlag,
      key: popUpBanner?.id,
      appConfigMode: popUpBanner?.conditions,
    });
    await logEvent(logParams);
    if (typeof action === 'function') {
      action();
      onCancel(action?.action_type !== 'CTA');
    }
    if (typeof action === 'object') {
      if (action.url) {
        const validUrl = action.url.includes('http');
        if (validUrl) {
          window.open(action.url, action.target || '_blank');
        }
      }
      if (action.close) onCancel(action?.action_type !== 'CTA');
    }
  };

  const handleClose = async () => {
    const logParams = Helpers.promoEventLog({
      promoType: 'popup',
      promoAction: 'dismissed',
      featureFlag: popUpBanner?.featureFlag,
      key: popUpBanner?.id,
      appConfigMode: popUpBanner?.conditions,
    });
    await logEvent(logParams);

    onCancel(true);
  };

  const [handleCsShow, setHandleCsShow] = useState(false);

  const { pathname } = useRouter();

  useEffect(() => {
    setHandleCsShow((!!popUpBanner && !notificationExludePaths.some((route) => pathname.includes(route)) && (popUpBanner?.end_date ? new Date(popUpBanner?.end_date).getTime() > Date.now() : true) && (popUpBanner?.conditions?.paid !== null ? (!popUpBanner?.conditions?.paid ? !userPlan?.expire_date : userPlan?.expire_date) : true)) && !iframePopupOpen && !Versioning.ONE_TIME_CREATION());
  }, [popUpBanner, pathname, iframePopupOpen]);

  const [hasLogEvents, setHasLogEvents] = useState([]);

  const logDisplay = async () => logEvent(Helpers.promoEventLog({
    promoType: 'popup',
    promoAction: 'displayed',
    featureFlag: popUpBanner.featureFlag,
    key: popUpBanner.id,
    appConfigMode: popUpBanner?.conditions,
  }));

  useEffect(() => {
    if (!!popUpBanner && handleCsShow) {
      if (!hasLogEvents.includes(popUpBanner.id)) {
        logDisplay();
        setHasLogEvents([...hasLogEvents, popUpBanner.id]);
      }
    }
  }, [popUpBanner, handleCsShow]);

  return (
    <ThemeWrapper>
      {handleCsShow && popUpBanner && (
        <AssemblrModal
          visible={!!popUpBanner}
          title={popUpBanner?.title}
          onClose={popUpBanner?.dismissable ? handleClose : undefined}
          dissmissable={popUpBanner?.dismissable}
          containerClassName={styles.popupContainer}
          bodyClassName={styles.popupBody}
          // disableScroll
          actions={(
            <div className={styles.actions}>
              {popUpBanner?.actions?.map((action, index) => (
                <Button
                  key={index}
                  type={action.type || 'default'}
                  danger={action.danger || false}
                  onClick={() => handleClick(action)}
                  size="large"
                >
                  {action.label}
                </Button>

              ))}
            </div>
          )}
        >
          <div dangerouslySetInnerHTML={{ __html: popUpBanner?.content }} suppressHydrationWarning />
        </AssemblrModal>
      )}
    </ThemeWrapper>
  );
}

export function UnpaidInvoicePopUp() {
  const dispatch = useDispatch();
  const { iframePopup: { open: iframePopupOpen } } = useSelector(appState);

  const _currentActiveNotification = useSelector(currentActiveNotification);

  const { user, userData } = useAuth();
  const { currency: { code } } = useSelector(appState);

  const unpaidInvoicePopUp = useMemo(() => {
    if (_currentActiveNotification?.type === 'unpaid_invoice' && _currentActiveNotification.payload?.type === 'popup') {
      if (_currentActiveNotification.payload?.pricing_plan?.plans_type === 'Personal' && _currentActiveNotification?.uid === user?.uid) {
        return _currentActiveNotification.payload;
      }
      if (unpaidTypeArray.includes(_currentActiveNotification.payload?.pricing_plan?.plans_type) && _currentActiveNotification?.uid === user?.uid) {
        dispatch(setInactiveNotification({ withCooldown: true }));
      }
      dispatch(setInactiveNotification({ withCooldown: false }));
    }
    return null;
  }, [_currentActiveNotification?.payload, _currentActiveNotification?.type, _currentActiveNotification?.uid, dispatch, user?.uid]);

  const eventParams = useMemo(() => ({
    order_id: unpaidInvoicePopUp?.order_id,
    product: unpaidInvoicePopUp?.pricing_plan?.name,
    product_type: unpaidInvoicePopUp?.pricing_plan?.plans_type,
    payment_type: unpaidInvoicePopUp?.payments_method === 'midtrans' ? 'Midtrans' : 'Stripe',
  }), [unpaidInvoicePopUp]);

  const { logEvent } = useAnalytics();

  const handleClose = async (hasCheckOut = false, invoice = '') => {
    if (invoice) {
      dispatch(setIframePopup({ open: true, url: `${paymentUrl()}/status/${invoice}?currency=${code}&token=${userData?.accessToken}` }));
    }

    if (!hasCheckOut) {
      await logEvent({
        eventName: 'unpaid_invoice_popup_dismissed',
        eventParams,
        posthog: true,
      });
      dispatch(setInactiveNotification({ withCooldown: true }));
    } else {
      dispatch(setInactiveNotification({ withCooldown: false }));
    }
  };

  const handleCheckOut = async () => {
    await logEvent({
      eventName: 'unpaid_invoice_popup_cta_clicked',
      eventParams,
      posthog: true,
    });

    handleClose(true, unpaidInvoicePopUp?.order_id);
  };

  const collapsedVariant = {
    collapsed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
    expanded: {
      height: 'auto',
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  const [expanded, setExpanded] = useState(false);

  const [handleCsShow, setHandleCsShow] = useState(false);

  const { pathname } = useRouter();

  useEffect(() => {
    setHandleCsShow(!!unpaidInvoicePopUp && !notificationExludePaths.some((route) => pathname.includes(route)) && !iframePopupOpen);
  }, [unpaidInvoicePopUp, pathname, iframePopupOpen]);

  const [hasLogEvents, setHasLogEvents] = useState([]);
  const logDisplay = async () => logEvent({
    eventName: 'unpaid_invoice_popup_displayed',
    eventParams,
    posthog: true,
  });

  useEffect(() => {
    if (unpaidInvoicePopUp) {
      if (!hasLogEvents.includes(unpaidInvoicePopUp?.order_id)) {
        logDisplay();
        setHasLogEvents([...hasLogEvents, unpaidInvoicePopUp?.order_id]);
      }
    }
  }, [unpaidInvoicePopUp]);

  // const [showSurvey, setShowSurvey] = useState(false);

  // const handleSurvey = (data) => {
  //   console.log({ xxx___: data });
  //   dispatch(setInactiveNotification({ withCooldown: false }));
  //   setShowSurvey(false);
  //   // TODO : Send to backend, cancel invoice
  // };

  return handleCsShow && unpaidInvoicePopUp && (
    <>
      <Modal
        open={!!unpaidInvoicePopUp}
        // title={`${Helpers.ucFirst(unpaidInvoicePopUp.pricing_code)} Plan`}
        centered
        onOk={handleCheckOut}
        onCancel={() => handleClose(false)}
        cancelText="Dismiss"
        okText={`Purchase (USD $${unpaidInvoicePopUp?.pricing_plan?.price - unpaidInvoicePopUp?.gross_amount}))`}
        maskClosable={false}
        footer={null}
        className="fullscreen-mobile-modal footer-always-bottom without-padding"
      >
        <div className={styles.unpaidInvoiceWrapper}>
          <div className={styles.unpaidInvoicePopUp}>
            <div className={styles.header}>
              <div className={styles.title}>
                You&apos;re only one step away to enjoy this plan 👀
              </div>
              <div className={styles.subtitle}>
                Purchase today to enjoy our exclusive features!
              </div>
            </div>
            <div className={styles.content}>
              <div
                className={classnames(styles.plan, styles.collapsed_main_plan, expanded && styles.expanded_main_plan)}
              >
                <div className={styles.main}>
                  <div
                    className={classnames(styles.img_wrapper)}
                    style={{
                      backgroundColor: `#${unpaidInvoicePopUp?.pricing_plan?.backgroundColor}`.replace('##', '#'),
                    }}
                  >
                    <img src={unpaidInvoicePopUp?.pricing_plan?.thumbnail} alt={Helpers.ucFirst(unpaidInvoicePopUp.pricing_code)} />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.name}>
                      {Helpers.ucFirst(unpaidInvoicePopUp.pricing_code)}
                      {' '}
                      Plan
                    </div>
                    <div className={styles.main_features}>
                      <p>
                        {(unpaidInvoicePopUp?.pricing_plan?.feature?.quota || 30000) / 1000000}
                        {' '}
                        MB Storage
                      </p>
                      <p>
                        {unpaidInvoicePopUp?.pricing_plan?.feature?.marker}
                        {' '}
                        Custom AR Markers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <motion.div
                className={styles.collapsed}
                variants={collapsedVariant}
                initial="collapsed"
                animate={expanded ? 'expanded' : 'collapsed'}
              >
                <div className={classnames(styles.plan, styles.collapsed_plan)}>
                  <div className={styles.features}>
                    <div className={styles.feature}>
                      <div className={styles.icon}>
                        <img src="/assets/icons/plans/selected.png" alt="Storage" />
                      </div>
                      <div className={styles.label}>
                        <b>
                          {(unpaidInvoicePopUp?.pricing_plan?.feature?.quota || 300000) / 1000000}
                          {' '}
                          MB
                        </b>
                        {' '}
                        Storage of Custom 3D Objects
                      </div>
                    </div>
                    <div className={styles.feature}>
                      <div className={styles.icon}>
                        <img src="/assets/icons/plans/selected.png" alt="Custom AR Marker" />
                      </div>
                      <div className={styles.label}>
                        <b>{unpaidInvoicePopUp?.pricing_plan?.feature?.marker}</b>
                        {' '}
                        Custom AR Markers
                      </div>
                    </div>
                    <div className={styles.feature}>
                      <div className={styles.icon}>
                        <img src="/assets/icons/plans/selected.png" alt="AR Project" />
                      </div>
                      <div className={styles.label}>
                        <b>Unlimited</b>
                        {' '}
                        AR Projects
                      </div>
                    </div>
                    {
                      unpaidInvoicePopUp?.pricing_plan?.feature?.qr_marker
                      && (
                        <div className={styles.feature}>
                          <div className={styles.icon}>
                            <img src="/assets/icons/plans/selected.png" alt="QR Marker" />
                          </div>
                          <div className={styles.label}>
                            <b>Unlimited</b>
                            {' '}
                            QR Markers
                          </div>
                        </div>
                      )
                    }
                    {
                      unpaidInvoicePopUp?.pricing_plan?.feature?.library_access
                      && (
                        <div className={styles.feature}>
                          <div className={styles.icon}>
                            <img src="/assets/icons/plans/selected.png" alt="2D, 3D, & AR Library" />
                          </div>
                          <div className={styles.label}>
                            <b>Unlimited</b>
                            {' '}
                            2D, 3D, & AR Library
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className={styles.billing}>
                  <div className={styles.container}>
                    <div className={styles.label}>
                      Billing Models
                    </div>
                    <div className={styles.sub_container}>
                      <div className={styles.title}>
                        {!unpaidInvoicePopUp.is_subscription ? 'One-Time Purchase' : 'Monthly Subscription'}
                      </div>
                      <div className={styles.subtitle}>
                        {!unpaidInvoicePopUp.is_subscription ? 'Pay only once in the beginning' : 'Pay monthly for the plan'}
                      </div>
                    </div>
                  </div>

                  <div className={styles.container}>
                    <div className={styles.label}>
                      Duration
                    </div>
                    <div className={styles.sub_container}>
                      <div className={styles.title}>
                        {unpaidInvoicePopUp.pricing_plan.group_plan.includes('school') ? '1 Year'
                          : `${unpaidInvoicePopUp.quantity} ${Helpers.ucFirst(unpaidInvoicePopUp.duration_code).replace('ly', '')}`}
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
              <div className={styles.collapse_container}>
                <button
                  className={styles.btn_collapse}
                  onClick={() => setExpanded(!expanded)}
                  type="button"
                >
                  {expanded ? 'Show Less' : 'Show More'}
                  {
                    !expanded ? <AiOutlineDown /> : <AiOutlineUp />
                  }
                </button>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            {/* <Button
                    onClick={() => setShowSurvey(true)}
                    size="large"
                    danger
                    className={styles.btn_cancel}
                >
                    Cancel Purchase
                </Button> */}
            {/* <div>

                    </div> */}
            <div className={styles.btn_right_container}>
              <Button
                onClick={() => handleClose(false)}
                size="large"
              >
                Dismiss
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={handleCheckOut}
              >
                Continue purchase
                {' '}
                {currFormat(
                  (unpaidInvoicePopUp?.gross_amount).toFixed(2),
                  unpaidInvoicePopUp?.pricing_plan?.currency,
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {/* <Modal
        className="without-padding"
        footer={null}
        onCancel={() => setShowSurvey(false)}
        title="Cancel Purchase"
        open={showSurvey}
        onHide={() => setShowSurvey(false)}
        centered
      >
        <SurveyCreator
          onOk={handleSurvey}
          surveyJsConfig={config({ email: user?.email })}
        />
      </Modal> */}
    </>
  );
}

export default PopupBannerWithPosthog;
