import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthenticationServices from '../../services/AuthenticationServices';
import OnBoardingServices from '../../services/OnBoardingServices';

const initialState = {
  user: null,
  userData: null,
  token: null,
  userMeta: {
    role: '', sector: '', interest: [], age_range: null, country_code: null,
  },
  loadingUserMeta: false,
  errorUserMeta: null,
  checkUser: null,
  loadingCheckUser: false,
  errorCheckUser: null,
  userPlan: null,
};

export const asyncGetUserMeta = createAsyncThunk(
  'auth/getUserMeta',
  async (uid) => {
    const response = await OnBoardingServices('auth reducer').getMyMetaOnBoarding(uid);
    const {
      role,
      subrole,
      sector,
      interest,
      age_range: ageRange,
      country_code: countryCode,
      currency_code: currencyCode,
    } = response;
    return {
      userMeta: {
        uid,
        role,
        subrole,
        sector,
        interest,
        age_range: ageRange,
        country_code: countryCode,
        currency_code: currencyCode,
        hasFetch: true,
      },
    };
  },
);

export const asyncCheckUser = createAsyncThunk(
  'auth/checkUser',
  async () => {
    const response = await AuthenticationServices().checkUser();
    return { checkUser: response };
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('_token', action.payload);
    },
    setUserMeta: (state, action) => {
      state.userMeta = action.payload;
    },
    setUserPlan: (state, { payload }) => {
      state.userPlan = payload;
    },
    clearUser: (state) => {
      state.user = null;
      state.userData = null;
      state.token = null;
      localStorage.removeItem('_token');
      localStorage.removeItem('userData');
      state.userMeta = null;
      state.loadingUserMeta = false;
      state.errorUserMeta = null;
      state.checkUser = null;
      state.loadingCheckUser = false;
      state.errorCheckUser = null;
      state.userPlan = null;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(asyncGetUserMeta.pending, (state) => {
      state.loadingUserMeta = true;
    });
    addCase(asyncGetUserMeta.fulfilled, (state, { payload }) => {
      state.userMeta = payload.userMeta;
      state.loadingUserMeta = false;
    });
    addCase(asyncGetUserMeta.rejected, (state, { payload }) => {
      state.errorUserMeta = payload?.error;
      state.loadingUserMeta = false;
    });

    addCase(asyncCheckUser.pending, (state) => {
      state.loadingCheckUser = true;
    });
    addCase(asyncCheckUser.fulfilled, (state, { payload }) => {
      state.checkUser = payload.checkUser;
      state.loadingCheckUser = false;
    });
    addCase(asyncCheckUser.rejected, (state, { payload }) => {
      state.errorCheckUser = payload?.error;
      state.loadingCheckUser = false;
    });
  },
});

export const {
  setUser, setUserData, setToken, setUserMeta, clearUser, setUserPlan,
} = authSlice.actions;

export const authState = (state) => state.auth;

export const isFreeUserState = (state) => !state.auth.userPlan
  || Object.keys(state.auth.userPlan).length === 0
  || (state.auth.userPlan?.expire_date && new Date(state.auth.userPlan?.expire_date) < new Date());

const authReducer = authSlice.reducer;

export default authReducer;
