import { checkIsRevalidate } from "../../utils/cacheRoutes";
import { LogWithPrefix } from "../../utils/Log";

const Log = new LogWithPrefix('RevalidateCacheInterceptor');

const revalidateCacheInterceptor = (config) => {
    if (typeof window === 'undefined') return config;
    if (config.method === 'get') {
        const isRevalidate = checkIsRevalidate(config.url);
        if (isRevalidate) {
            config.headers['Cache-Control'] = 'no-store';
        }
    }
    return config;
}

export default revalidateCacheInterceptor;