import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    popupPaymentStatus: {
        status: 'idle',
        invoiceId: null,
        result: null,
    },
}

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        setPopupPaymentStatus(state, action) {
            state.popupPaymentStatus = {
                ...state.popupPaymentStatus,
                ...action.payload,
            }
        }
    }
})

export const paymentState = state => state.payment

export const { setPopupPaymentStatus } = paymentSlice.actions

const paymentReducer = paymentSlice.reducer

export default paymentReducer