import { useEffect, useState } from "react";
import userAgentUtils from "../utils/userAgent";

const useUserAgent = () => {
    const [userAgent, setUserAgent] = useState(null);
    const [screenWidth, setScreenWidth] = useState(null);
    const [screenHeight, setScreenHeight] = useState(null);

    const loadScreenWidthAndHeight = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
    }

    useEffect(() => {
        setUserAgent(window.navigator.userAgent);
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);

        window.addEventListener('resize', () => {
            loadScreenWidthAndHeight();
        });

        loadScreenWidthAndHeight();

        return () => {
            window.removeEventListener('resize', () => {
                loadScreenWidthAndHeight();
            });
        }
    }, []);

    const screenWidthGreaterThan = (width) => {
        return screenWidth > width;
    }

    const screenHeightGreaterThan = (height) => {
        return screenHeight > height;
    }

    const screenWidthLessThan = (width) => {
        return !!(screenWidth && (screenWidth < width)); //agar dia return false kalau screenWidth nya masih null
    }

    const screenHeightLessThan = (height) => {
        return screenHeight < height;
    }

    const isMobile = userAgentUtils.isMobile;

    return {
        userAgent,
        screenWidth,
        screenHeight,
        screenWidthGreaterThan,
        screenHeightGreaterThan,
        screenWidthLessThan,
        screenHeightLessThan,
        isMobile,
    }
}

export default useUserAgent;