import { LoaderFullScreen, ThemeWrapper } from 'assemblr-ui';
import classnames from '../../../utils/classnames';
import styles from './loader.module.scss';

export default function Loader({ show = true, message }) {
  return (
  // <div className={classnames('scrim-loading loading', show ? styles.show : styles.hide)}>
  //   <div className={styles.circular2}>
  //     <div className={styles.inner2} />
  //     <div className={styles.circle2} />

  //     <div className={styles['full-circle']} />

    //     <div className={styles.counter}>
    //       <Image width={100} height={100} alt="" src="/assets/images/blr_icon.png" />
    //     </div>
    //     <div className={styles['circular-container']} />
    //   </div>
    //   <div className={styles.text}>{message}</div>
    // </div>
    <ThemeWrapper>
      <LoaderFullScreen className={classnames(show ? styles.show : styles.hide, 'z-[999999]')}>
        <p>{message}</p>
      </LoaderFullScreen>
    </ThemeWrapper>
  );
}
