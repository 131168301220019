const shouldIgnoreError = (error) => {
  const ignoredErrors = [
    'Minified React error #418', // This is a warning from React
    'Minified React error #423', // This is a warning from React
    'Minified React error #425', // This is a warning from React
    "Messaging: This browser doesn't support the API", // This is a warning from Firebase
    'Connection to Indexed Database server lost', // This is a warning from IndexedDB
    'ResizeObserver', // This is a warning from ResizeObserver at Library
  ];

  const isIgnored = ignoredErrors.some((ignoredError) => error.message && error.message.includes(ignoredError));
  if (isIgnored) {
    return true;
  }

  return false;
};

const NewRelic = {
  script: `https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/scripts/newrelic/${process.env.NODE_ENV === 'production' ? 'studio' : 'dev'
    }.js`,
  setUserId: (uid) => {
    if (typeof window !== 'undefined' && window.newrelic && typeof window.newrelic.setUserId === 'function') {
      if (window.newrelic.userId !== uid) {
        console.log('Set user id to new relic', uid);
        window.newrelic.setUserId(uid);
        window.newrelic.userId = uid;
      }
    }
  },
  setErrorHandler: () => {
    if (typeof window !== 'undefined' && window.newrelic && typeof window.newrelic.setErrorHandler === 'function') {
      window.newrelic.setErrorHandler((shouldIgnoreError));
    }
  },

  noticeStackTrace: ({ uid, event }) => {
    try {
      const allowedUsers = [
        {
          event: 'signOut',
          uids: [
            'ZIJ2rlxUANhQvXmo8w5ytqeDZxA2',
            'u0Yk9KCjnJTYBtkbk0a4LqO3YFu2',
            'oqZUVCuZ4relyza0XOe5BQEL32o2',
            // 'CM3ehu55lbSPUIALlwayw8QhkAI3',
          ],
        },
      ];

      const isAllowedUser = allowedUsers.some((allowedUser) => {
        if (allowedUser.event === event) {
          return allowedUser.uids.includes(uid);
        }
        return false;
      });

      if (!isAllowedUser) {
        return;
      }

      const error = new Error(`noticeStackTrace: ${event} from ${uid}`);
      fetch('https://firestore.googleapis.com/v1/projects/todos-131f7/databases/(default)/documents/noticeStackTrace', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: {
            uid: {
              stringValue: uid,
            },
            event: {
              stringValue: event,
            },
            stackTrace: {
              stringValue: error.stack,
            },
            timestamp: {
              timestampValue: new Date().toISOString(),
            },
          },
        }),
      });
    } catch {
      // Do nothing
    }
  },
};

export default NewRelic;
