import {
  child, get, getDatabase, ref,
} from 'firebase/database';
import nookies from 'nookies';
import axios from 'axios';
import apiService from './apiService';

const AppServices = {
  getListApp: async ({ keys = [], startWith = null }) => {
    const uri = startWith ? `/settings/list-app/?startwith=${startWith}` : `/settings/list-app/${keys.length > 0 ? `?key=${keys.join(',')}` : ''}`;
    const { data } = await apiService.get(uri);
    return data;
  },
  getUserInfo: async (uid) => {
    try {
      const cache = await caches.open('userInfo');
      const cachedResponse = await cache.match(uid);
      if (cachedResponse) {
        const data = await cachedResponse.json();
        if (data.lastUpdate && data.lastUpdate > new Date().getTime() - (1000 * 60 * 5)) {
          return data;
        }
      }
    } catch (e) {
      console.log(e);
    }

    const db = getDatabase();
    const dbRef = ref(db, '/');
    const snap = await get(child(dbRef, `/UserProfile/${uid}`));
    const userInfo = await snap.val();

    try {
      const cache = await caches.open('userInfo');
      const data = {
        ...userInfo,
        lastUpdate: new Date().getTime(),
      };
      cache.put(uid, new Response(JSON.stringify(data)));
    } catch (e) {
      console.log(e);
    }

    return userInfo;
  },
  getGeoIp: async () => {
    try {
      const cookies = nookies.get();
      const country = cookies.geo_pref;

      if (country && String(country) !== 'null') {
        return {
          country: country.toLowerCase?.(),
        };
      }

      const { data } = await axios.get(process.env.NEXT_PUBLIC_GEOIP_API_URL || 'https://utils.d1o2otincy91d.amplifyapp.com/api/geoip');

      nookies.set({}, 'geo_pref', data?.geo?.country || null, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
        ...(window.location.hostname.includes('assemblrworld.com') && { domain: 'assemblrworld.com' }),
      });

      return {
        country: data?.geo?.country?.toLowerCase?.() || null,
      };
    } catch (e) {
      console.log(e);
      return {
        country: null,
      };
    }
  },
  userGeoIp: () => {
    const cookies = nookies.get();
    return {
      country: cookies?.geo_pref?.toLowerCase?.() || null,
    };
  },
  getEnterpriseUids: async () => {
    const { data } = await apiService.get('/v2/enterprise/list-exclusive-id/');
    return data;
  },
};

export default AppServices;
