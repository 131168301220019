import { useRouter } from 'next/router';
import { NameGenerator } from '../components/miscellaneous/name_generator';
import { editorUrl, paymentUrl } from '../config/getdomain';
import AuthenticationServices from '../services/AuthenticationServices';
import CreationServices from '../services/CreationServices';
import { clearCacheByKeys, MY_CREATION_CACHE_KEY } from '../utils/cacheRoutes';
import userAgent from '../utils/userAgent';
import useAuth from './useAuth';
import useEditorCount from './useEditorCount';
import cloneProject from '../services/cloneProject';
import useAnalytics, { currentDistinctId } from './useAnalytics';
import { appState, setIframePopup } from '../store/reducers/appReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { authState } from '../store/reducers/authReducer';
import { analyticTracking } from '../components/controllers/analytic_tracking';

const useCreations = () => {
  const { checkAuth, user } = useAuth();
  const { asPath } = useRouter();
  const { recordOpenEditor } = useEditorCount();
  const { logEvent } = useAnalytics();
  const { currency: { code: currencyCode } } = useSelector(appState);
  const dispatch = useDispatch();

  const { userPlan: currentPlan = {
    loading: true,
  } } = useSelector(authState)

  const iframeUrl = useMemo(() => {
    const url =
      // navigator.userAgent.toLowerCase().includes('assemblr')
      //   ? `${paymentUrl()}/Marker?embed=true&back_url=/profile/markers&distinctId=${currentDistinctId()}`
      //   :
      `${paymentUrl()}/new/checkout?distinctId=${currentDistinctId()}&currency=${currencyCode}&type=limited_addons&group=extra_project`;
    return url;
  }, [currencyCode]);

  const iframeMarkerUrl = useMemo(() => {
    const url = userAgent.isMobile.Assemblr()
      ? `${paymentUrl()}/Marker?embed=true&back_url=/profile/markers&distinctId=${currentDistinctId()}`
      : `${paymentUrl()}/new/checkout?distinctId=${currentDistinctId()}&currency=${currencyCode}&type=miscellaneous&group=custommarker`;
    return url;
  }, [currencyCode]);

  const PaidUser = useMemo(() => {
    if (currentPlan?.loading) return {
      activated: false,
      extraProject: false,
      personal: false,
      loading: true,
      ...currentPlan,
    }
    console.log({ currentPlan });
    const activated = (currentPlan?.expire_date && new Date(currentPlan?.expire_date) > new Date()) || false;

    const extraProject = activated && currentPlan?.plans_group === 'extra_project';
    const personal = activated && currentPlan?.plans_group?.includes('pppp') || false;

    return {
      activated,
      extraProject,
      personal,
      loading: false,
      ...currentPlan,
    }
  }, [currentPlan]);

  const openBuyCreationQuota = ({ withIntro = !PaidUser?.library_access || PaidUser?.pricing_code === 'Extra' } = { withIntro: !PaidUser.library_access || PaidUser?.pricing_code === 'Extra' }) => {
    console.log({ PaidUser });
    dispatch(setIframePopup({
      url: withIntro ? iframeUrl?.replaceAll('new/checkout', 'page/extra-from-editor') : iframeUrl,
      open: true,
    }));
  };

  const openUpgradePlan = () => {
    const url = new URL(iframeUrl);
    // remove all params
    url.search = '';
    url.pathname = 'Plan';
    url.searchParams.append('embed', 'true');

    dispatch(setIframePopup({
      url,
      open: true,
    }));
  };

  const openBuyMarker = (afterClosePayment = () => { }) => {
    analyticTracking(user.uid, 'custom_marker_profile', 'upgrade_plan');
    dispatch(setIframePopup({ open: true, url: iframeMarkerUrl }));
    const mobileNav = document.getElementById('mobile_nav');
    if (mobileNav) mobileNav.classList.remove('fixed_mobile_nav');
    window.addEventListener('message', (e) => {
      if (e.data === 'close popup') {
        dispatch(setIframePopup({ open: false, url: '' }));
        const mobileNavEl = document.getElementById('mobile_nav');
        if (mobileNavEl) mobileNavEl.classList.add('fixed_mobile_nav');
        try {
          afterClosePayment?.();
        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  const openEditor = async ({
    projectId, withParams = {}, openNewTab = true, mode = 'default',
  }) => {
    checkAuth();
    clearCacheByKeys(MY_CREATION_CACHE_KEY);
    const editorAuthKey = await AuthenticationServices().generateToken();
    const isAssembler = userAgent.isMobile.Assemblr();
    // eslint-disable-next-line no-unused-vars
    const { templateID = null, ...newParams } = withParams;
    const data = {
      authKey: editorAuthKey,
      sourceDomain: window.location.origin,
      'mobile-app': isAssembler ? 'true' : 'false',
      ...newParams,
    };

    if (mode === 'simple') {
      data.simpleEditor = projectId;
    } else {
      data.editor = projectId;
    }

    const params = new URLSearchParams(data);

    const url = `${editorUrl()}/Auth?${params.toString()}`;
    recordOpenEditor();
    if (isAssembler) {
      window.location = url;
    } else if (openNewTab) window.open(url, '_blank');
    else window.open(url, '_self');
  };

  const createProject = async ({
    withParams = {}, openNewTab = false, name = NameGenerator(), category = [], mode = 'default',
  } = {
      withParams: {}, openNewTab: false, name: NameGenerator(), category: [], mode: 'default',
    }) => {
    try {
      checkAuth();
      const data = { name, category };
      if (mode === 'simple') {
        data.build_mode = 4;
      }

      if (window._lpcp) {
        return null;
      }
      window._lpcp = true;
      const { Pblr: projectId } = await CreationServices.create({ ...data, based_project_id: withParams?.templateID });
      logEvent({
        eventName: 'create_new',
        eventParams: {
          template: withParams?.templateID || 'blank',
          category: 'blank',
          referer: asPath,
          mode,
        },
      });
      if (withParams?.templateID) {
        const res = await cloneProject(withParams.templateID, projectId, user.uid);
        if (!res) return null;
      }

      await openEditor({
        projectId, withParams, openNewTab, mode,
      });

      window._lpcp = false;
      return projectId;
    } catch (error) {
      window._lpcp = false;
      throw error;
    }
  };

  return {
    createProject,
    openEditor,
    openBuyCreationQuota,
    PaidUser,
    openUpgradePlan,
    openBuyMarker,
  };
};

export default useCreations;
