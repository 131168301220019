import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  currentActiveNotification, notificationExludePaths, queueNotification, setInactiveNotification,
} from '../store/reducers/notificationReducer';
import { posthogState } from '../store/reducers/posthogReducer';
import useAuth from './useAuth';
import useAnalytics from './useAnalytics';

const useTallyWidget = () => {
  const dispatch = useDispatch();
  const { tallySurveys } = useSelector(posthogState);

  const { user } = useAuth();

  useEffect(() => {
    tallySurveys?.forEach((tallySurvey) => {
      dispatch(queueNotification({
        type: 'tallySurvey',
        key: `tallySurvey-${tallySurvey.id}`,
        payload: tallySurvey,
      }));
    });
  }, [dispatch, tallySurveys]);

  const _currentActiveNotification = useSelector(currentActiveNotification);

  const tallySurvey = useMemo(() => {
    if (_currentActiveNotification?.type === 'tallySurvey') {
      return _currentActiveNotification?.payload;
    }
    return null;
  }, [_currentActiveNotification]);

  const { logEvent } = useAnalytics();

  const logTallySurvey = useCallback((eventName, payload = {}) => {
    logEvent({
      eventName,
      eventParams: {
        tallySurveyId: tallySurvey?.id,
        featureFlagKey: tallySurvey?.featureFlag,
        ...payload,
      },
      posthog: true,
    });
  }, [logEvent, tallySurvey]);

  const { pathname } = useRouter();

  useEffect(() => {
    if ('Tally' in window && tallySurvey) {
      const { Tally } = window;
      if (!user || notificationExludePaths.some((route) => pathname.includes(route))) {
        try {
          Tally.closePopup(tallySurvey.id);
        } catch (error) {
          console.log({ xxx__error: error });
        }
        dispatch(setInactiveNotification({ withCooldown: false }));
        return;
      }
      const hasRendered = document.getElementsByClassName(`tally-form-${tallySurvey.id}`).length > 0;
      if (!hasRendered) {
        const options = {
          onOpen: () => {
            logTallySurvey('tally_opened');
          },
          onClose: () => {
            logTallySurvey('tally_closed');
            dispatch(setInactiveNotification({ withCooldown: false }));
          },
          onPageView: (page) => {
            logTallySurvey('tally_pageviewed', { page });
          },
          onSubmit: (payload) => {
            logTallySurvey('tally_submitted', payload);
            dispatch(setInactiveNotification({ withCooldown: false }));
          },
          ...tallySurvey.options,
          hiddenFields: {
            ...tallySurvey.options?.hiddenFields,
            email: user?.email,
            uid: user?.uid,
          },
        };
        console.log({ xxx__tallyOptions: options });

        Tally.openPopup(tallySurvey.id, options);
      }
    }
  }, [dispatch, logTallySurvey, pathname, tallySurvey, user]);

  return tallySurvey;
};

export default useTallyWidget;
