import { SET_USERDATA, ERROR } from "../types";

export const setUserDetail = (userData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USERDATA,
      payload: userData,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: "error message",
    });
  }
};