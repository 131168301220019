import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './baseQuery';

export const asblrApi = createApi({
  reducerPath: 'asblrApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['MyCreation'],
  endpoints: (builder) => ({
    getMyCreations: builder.query({
      query: ({
        page = 1, pageSize = 10, type = 'all',
      }) => {
        console.log('___rpage', page, 'pageSize', pageSize, 'type', type);
        let url = `/v2/creations/my-creations/?page=${page}&row=${pageSize}`;
        if (type === 'published') {
          url += '&published=True';
        }

        if (type === 'unpublished') {
          url += '&published=False';
        }

        if (type === 'draft') {
          url += '&published=Null';
        }

        if (type === 'notduplicable') {
          url += '&is_template=False';
        }

        return { url, method: 'GET' };
      },
      providesTags: (result) => (result
        ? [...result.creations.map(({ Pblr, Id }) => ({
          type: 'MyCreation', id: Pblr || Id,
        })), { type: 'MyCreation', id: 'PARTIAL_LIST' }]
        : [{ type: 'MyCreation', id: 'PARTIAL_LIST' }]),
      transformResponse: (response, meta, arg) => {
        switch (arg.type) {
          default:
            return response;
        }
      },
    }),
    deleteCreation: builder.mutation({
      query: ({ pblrId }) => ({
        url: '/v2/creations/delete-creations/',
        method: 'POST',
        data: {
          creations: pblrId,
        },
      }),
      invalidatesTags: (result, error, arg) => (result ? [{ type: 'MyCreation', id: arg.pblrId }] : []),
    }),
    featureTotal: builder.query({
      query: ({ uid }) => ({ url: `/v2/features/total/${uid}` }),
    }),
    getMyMarkers: builder.query({
      query: () => ({ url: '/v2/markers/user-marker/' }),
    }),
  }),
});

export const {
  useGetMyCreationsQuery,
  useDeleteCreationMutation,
  useFeatureTotalQuery,
  useGetMyMarkersQuery,
} = asblrApi;
