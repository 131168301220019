import { GET_LOGIN, ERROR } from "../types";

const initialState = {
  login: "",
  loading: true,
};

const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN:
      return {
        ...state,
        login: action.payload,
        loading: false,
      };

    case ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default popupReducer;