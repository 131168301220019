import { getAuth } from 'firebase/auth';
import apiService from './apiService';

const OnBoardingServices = () => {
  const { currentUser } = getAuth();

  const updateOrCreateRoleAndIndustry = async ({
    role, industry, subrole, interests, sector, ...params
  }) => {
    const payload = {};
    if (role) payload.role = role;
    if (industry) payload.industry = industry;
    if (subrole) payload.subrole = subrole;
    if (sector) payload.sector = sector;
    if (interests) payload.interest = interests;
    const { data } = await apiService.post('/user-profile/meta/', { ...payload, ...params });
    return data;
  };

  const getMyMetaOnBoarding = async (uid) => {
    try {
      if (!uid) throw new Error({ status: 404 });
      const { data } = await apiService.get(`/user-profile/meta/detail/${uid}`);
      if (data?.detail === 'Not found.') {
        return {};
      }
      return data;
    } catch (error) {
      console.error({ error },'_____um');
      if (error.status === 404 || error.status === 500) {
        return {
          role: '', sector: '', interest: [], age_range: '', country_code: '', uuid: currentUser?.uid,
        };
      } return {
        role: 'skip', sector: 'skip', interest: ['skip'], age_range: 'skip', country_code: 'skip', uuid: currentUser?.uid,
      };
    }
  };

  const getVideoOnboard = async () => {
    try {
      const { data } = await apiService('/content/onboardvideos/?is_4content=true');
      return data;
    } catch (err) {
      console.log({ err });
      return [];
    }
  };

  return {
    updateOrCreateRoleAndIndustry,
    getMyMetaOnBoarding,
    getVideoOnboard,
  };
};

export default OnBoardingServices;
