import { GET_DATA, SET_DATA } from "../types";

const initialState = {
  data: {},
  loading: true,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case SET_DATA:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default dataReducer;