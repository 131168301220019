import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import fetchAdapter from "../services/fetchAdapter";
import API_URL from "./API_URL";

axios.defaults.adapter = fetchAdapter;

let _store = null;

export const injectStoreToApiRequest = (store) => {
  _store = store;
};

export const getRequest = (path) => {
  axios
    .get(API_URL + path)
    .then((res) => {
      return res;
    })
    .catch(() => {
      return null;
    });
};

export const getAsyncRequest = async (path) => {
  return await new Promise((resolve) => {
    let url = API_URL
    axios
      .get(url + path)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.error(err)
        resolve(null);
      });
  });
};

export const getAsyncRequestWithToken = async (path, data = {}) => {
  const auth = getAuth();
  let token = _store?.getState()?.auth?.token;
  if (auth) {
    if (!token) {
      token = await getIdToken(auth.currentUser);
    }
    return await new Promise((resolve) => {
      axios
        .get(API_URL + path, {
          headers: { Authorization: `${token}` }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err)
          resolve(null);
        });
    });
  } else {
    return null;
  }
};

export const deleteAsyncRequestWithToken = async (path, data = {}) => {
  const auth = getAuth();
  if (auth) {
    let token = await getIdToken(auth.currentUser);
    return await new Promise((resolve) => {
      axios
        .delete(API_URL + path, {
          headers: { Authorization: `${token}` }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err)
          resolve(null);
        });
    });
  } else {
    return null;
  }
};
