const mapByKeys = (obj, keys = [], exclude = false) => {
    const newObj = {};
    Object.keys(obj).forEach(key => {
        if (exclude) {
            if (!keys.includes(key)) {
                newObj[key] = obj[key];
            }
        } else {
            if (keys.includes(key)) {
                newObj[key] = obj[key];
            }
        }
    });
    return newObj;
};

export default mapByKeys;